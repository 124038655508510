import React from 'react';
import styles from "./visa-item.module.scss";
import CountryFlag from '../country-flag/country-flag';
import { visaTypes, visaGroups } from '../../../config/project-config';
import IconHome from '../icon/icon-home';
// import IconVisaFree from '../icon/icon-visa-free';
// import IconVisaRequired from '../icon/icon-visa-required';

const VisaItem = ({country, code, visaGroupName, visaType, isCurrentItem = false}) =>{

    return(
        <>
            <div className={styles.container}>
                <div className={styles.country}>
                    <CountryFlag country={country} code={code} />
                </div>
                {visaType && (
                    <span className={styles.visaLable}>{visaTypes[visaType].short_label}</span>
                )}
                {!isCurrentItem ? (
                    <span className={styles.visaIcon} style={{backgroundColor: visaGroups[visaGroupName].color,  color: visaGroupName === 'visa_free'? '#fff' : '#405363'}}>
                        {visaGroups[visaGroupName].icon}
                    </span>
                )
                :(
                    <span className={styles.visaIcon}><IconHome /></span>
                )}
             
            </div>
        </>
    )
}

export default VisaItem;