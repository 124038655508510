import React, { useState, useEffect } from 'react';
import styles from  './historical-rank.module.scss';
import useData from '../../hooks/useData';
import PopUpContentGraph from '../common/popup/popup-content-graph';
import LoadingSpinner from '../common/loading/loading-spinner';
import bakground from '../../assets/img/background-blur.png';
import CloseButton from '../common/button/button-close';

const HistoricalRankPerCountry = ({countryCode}) => {

    const {countries, loading} = useData();
    const [selectedCountry, setSelectedCountry] = useState('');
    // const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAndSetDefaultCountry = async () => {
          if (countries && countryCode) {
            const defaultCountry = countries.filter(country => country.code.toLowerCase() === countryCode.toLowerCase());
      
            if (defaultCountry[0]) {
                setSelectedCountry(defaultCountry[0]);
                // setIsLoading(false);
            }
          }
        };
      
        fetchAndSetDefaultCountry();
    }, [countryCode, countries]);

    // console.log(selectedCountry);

    if(!selectedCountry && loading) return <LoadingSpinner />;
    if(!selectedCountry) return <div>No Data</div>;

    return (
      <div className={styles.component_wrapper}>
        <div className={styles.background}>
          <img src={bakground} alt={selectedCountry.country}/>
        </div>
        <div className={styles.wrapper}>
          <CloseButton linkUrl={`https://www.henleyglobal.com/passport-index/ranking?countrycode=${countryCode}`} />
          <PopUpContentGraph 
              code={selectedCountry.code}
              country={`${selectedCountry.country}'s  Historical Ranking on the Henley Passport Index`}
              data={selectedCountry}
              isInitialLoad={true}
              contentType='popup'
          />
        </div>
      </div>
    );
  }
  
  export default HistoricalRankPerCountry;