const IconSearch = () =>{

    return(

        <svg width="20px" height="20.4661326px" viewBox="0 0 20 20.4661326">
            <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-370.000000, -202.000000)" fill="#c1c5c8" fillRule="nonzero">
                    <g transform="translate(80.000000, 120.000000)">
                        <g transform="translate(0.000000, 60.000000)">
                            <g transform="translate(290.000000, 22.000000)">
                                <path d="M7.92425346,0 C3.55244003,0 0,3.55244003 0,7.92425346 C0,12.2960669 3.55244003,15.8485069 7.92425346,15.8485069 C9.65404248,15.8485069 11.2527312,15.2913331 12.5564457,14.3481428 L18.6890022,20.4661326 L20,19.1551347 L13.9402768,13.0808449 C15.1311,11.6933724 15.8485069,9.8925709 15.8485069,7.92425346 C15.8485069,3.55244003 12.2960669,0 7.92425346,0 Z M7.92425346,0.932265113 C11.791697,0.932265113 14.9162418,4.05680991 14.9162418,7.92425346 C14.9162418,11.791697 11.791697,14.9162418 7.92425346,14.9162418 C4.05680991,14.9162418 0.932265113,11.791697 0.932265113,7.92425346 C0.932265113,4.05680991 4.05680991,0.932265113 7.92425346,0.932265113 Z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

}

export default IconSearch;