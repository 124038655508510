const  IconEVisa = () => {

    return (
        <svg width="14px" height="10px" viewBox="0 0 14 10">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-345, -167)">
                    <g transform="translate(0, 148)">
                        <g transform="translate(345, 19)">
                            <g transform="translate(5, 3)" fill="currentColor" fillRule="nonzero">
                                <path d="M2.16122004,4 C1.5221496,4 1.00217865,3.82828283 0.60130719,3.48484848 C0.20043573,3.14141414 0,2.68211527 0,2.10695187 L0,1.98573975 C0,1.60308972 0.0893246187,1.26143791 0.267973856,0.960784314 C0.446623094,0.660130719 0.69644154,0.424836601 1.01742919,0.254901961 C1.33841685,0.0849673203 1.68627451,0 2.06100218,0 C2.67392883,0 3.1503268,0.165181224 3.49019608,0.495543672 C3.83006536,0.82590612 4,1.29887106 4,1.9144385 L4,2.18894831 L0.806100218,2.18894831 C0.81771968,2.56922163 0.95352215,2.87641117 1.21350763,3.11051693 C1.4734931,3.3446227 1.80392157,3.46167558 2.20479303,3.46167558 C2.48946986,3.46167558 2.73057371,3.41414141 2.92810458,3.31907308 C3.12563544,3.22400475 3.29847495,3.09803922 3.44662309,2.94117647 L3.93899782,3.25490196 C3.54393609,3.75163399 2.9513435,4 2.16122004,4 Z M2.06100218,0.541889483 C1.73565723,0.541889483 1.46259985,0.638740345 1.24183007,0.832442068 C1.02106028,1.02614379 0.88453159,1.29768271 0.832244009,1.64705882 L3.19389978,1.64705882 L3.19389978,1.59714795 C3.17066086,1.26203209 3.06027596,1.00237671 2.8627451,0.818181818 C2.66521423,0.633986928 2.39796659,0.541889483 2.06100218,0.541889483 Z"></path>
                            </g>
                            <rect stroke="currentColor" x="0.5" y="0.5" width="13" height="9"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconEVisa;
