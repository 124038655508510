import { useState, useCallback } from 'react';
import { apiService } from '../services/api';
// import axios from 'axios';

// const useVisaData = () => {

//   const [visaData, setVisaData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);


//   const fetchVisaData = async (code) => {
//     setLoading(true);
//     try {
//       // const response = await axios.get(`/api/visa-single-sample/${code}.json`);
//       const response = await api.get(`/visa-single/${code}`);
//       console.log(response);
//       setVisaData(response.data);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return {visaData, loading, error, fetchVisaData};
// };

// export default useVisaData;

const useVisaData = () => {
  const [visaData, setVisaData] = useState(null);
  const [visaDataLoading, setVisaDataLoading] = useState(false);
  const [visaDataError, setVisaDataError] = useState(null);

  const fetchVisaData = useCallback(async (code) => {
    setVisaDataLoading(true);
    try {
      const response = await apiService.get(`/visa-single/${code}`);
      // console.log(response);
      setVisaData(response.data);
    } catch (error) {
      setVisaDataError(error);
    } finally {
      setVisaDataLoading(false);
    }
  }, []); // Dependencies array, add any dependencies here

  return { visaData, visaDataLoading, visaDataError, fetchVisaData };
};

export default useVisaData;