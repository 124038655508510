import React from 'react';
import styles from "./button.module.scss";
import globalRankIconImage from '../../../assets/img/icon-global-rank.png';
import compareIconImage from '../../../assets/img/icon-compare.png';
import improveIconImage from '../../../assets/img/icon-improve.png';
import shareIconImage from '../../../assets/img/icon-share.png'
import IconView from '../icon/icon-view';
import IconDownload from '../icon/icon-download';

const Button = ({id, linkUrl, target, buttonText, buttonStyle, iconStyle, onClick}) =>{

    const buttonStyleClass = () => {
        if(buttonStyle === 'outline'){
            return styles.button_primary_outline;
        }else if(buttonStyle === 'primary'){
            return styles.button_primary;
        }else if(buttonStyle === 'text'){
            return styles.no_border;
        }else{
            return styles.button_primary;
        }
    }

    const icon = (iconStyle) => {
        switch (iconStyle){
            case 'download':
                return <span className={styles.buttonIcon}><IconDownload /></span>;
            case 'global':
                return <span className={styles.buttonIcon}><img src={globalRankIconImage} alt="global rank" /></span>;
            case 'compare':
                return <span className={styles.buttonIcon}><img src={compareIconImage} alt="Compare my passport" /></span>;
            case 'improve':
                return <span className={styles.buttonIcon}><img src={improveIconImage} alt="Improve my passport" /></span>;
            case 'share':
                return <span className={styles.buttonIcon}><img src={shareIconImage} alt="Share my passport" /></span>;
            case 'eye':
                return <span className={styles.buttonIcon}><IconView /></span>;
            default:
                return '';
        }
    }



    return (
        <a
            id={id}
            className={`${styles.button} ${buttonStyleClass()}`}
            href={linkUrl}
            onClick={onClick}
            target={target}
        >
            {icon(iconStyle)}
            {buttonText}
        </a>
    )
}

export default Button;