const  IconClose= () => {

    return (
        <svg width="16.4142136px" height="16.4142136px" viewBox="0 0 16.4142136 16.4142136">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-272.7929, -513.7929)">
                    <g transform="translate(41, 502)" stroke="#405363" strokeWidth="2">
                        <g transform="translate(220, 0)">
                            <g transform="translate(12.5, 12.5)">
                                <path d="M1.77635684e-15,0 L15,15 M-8.8817842e-16,15 L15,1.77635684e-15"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconClose;
