import { visaTypes} from "../config/project-config";

export const getVisaOfType = (visaData, type) =>{
    if(!visaData[type]){
      // return visaData[type] = [];
      return [];
    }

    return visaData[type].map((item) => ({
      name: item.name,
      code: item.code,
      type: type,
      note: visaTypes[type].label
    }));
}

export const getTotalVisaListOfCategory = (visaData, visaCategories, visaGroupName) =>{

  return visaCategories.flatMap(category => {
    // Get visa list for the given category
    const visaListForCategory = getVisaOfType(visaData, category);
    
    // Return a new list with the category added to each item
    // This avoids mutating the original visa items and respects immutability
    return visaListForCategory.map(visaItem => ({
      ...visaItem, // Spread syntax to copy properties of the original visaItem
      groupName: visaGroupName, // Add the new property
    }));
  });
}
  
// export const getTotalVisaListOfCategory = (visaData, visaCategories) =>{
//     // let totalList = [];
//     // let flatList= [];
//     // for(let i = 0; i < visaCategory.length; i++){
//     //   totalList.push(getVisaOfType(visaData, visaCategory[i]))
//     // }
//     // flatList = totalList.flat();
//     // return flatList;
//      // Map over each visa category, transform and accumulate the visa data
//   const totalList = visaCategories.map(category => getVisaOfType(visaData, category));

//   // Flatten the array of visa data and return
//   return totalList.flat();
// }
  
export const mergeAndFilterArrays = (dataArray, key) => {
    const mergedArray = [];
    
    dataArray.forEach((item) => {
      if(item[key]){
        item[key].forEach((element) => {
          const existingElement = mergedArray.find((existing) => existing.code === element.code);
          if (!existingElement) {
            mergedArray.push(element);
          }
        });
      }
     
    });
    
    return mergedArray;
  };

  export const mergeArrayPerGroup = (compareVisaData, dataTypes) =>{
    const mergedData = dataTypes;
    for(const key in mergedData){
      mergedData[key] = mergeAndFilterArrays(compareVisaData, key);
    }
    return mergedData;
  }

  export const mergeArrays = (...arrays) =>{
    // Create a new Set to store unique values
    const uniqueValues = new Set();
  
    // Iterate through each array and add its values to the Set
    arrays.forEach((arr) => {
      arr.forEach((value) => {
        uniqueValues.add(value);
      });
    });
  
    // Convert the Set back to an array and return the result
    return Array.from(uniqueValues);
  }

  export const getPassportInfo = (code, data) =>{
    let filteredData = data.filter((passport) => passport.code === code);
    return filteredData[0];
  }

  export const getVisaListPerCountry = (code, visaDataArray) =>{
    const filteredResult = visaDataArray.find((passport) => passport.code === code);

    return {
      visa_free_access: filteredResult.visa_free_access,
      visa_on_arrival: filteredResult.visa_on_arrival,
      electronic_travel_authorisation: filteredResult.electronic_travel_authorisation,
      visa_online: filteredResult.visa_online,
      visa_required: filteredResult.visa_required
    };
  }