const  IconVOA = () => {

    return (
        <svg width="12px" height="12px" viewBox="0 0 12 12">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-346, -357)" stroke="currentColor">
                    <g transform="translate(0, 339)">
                        <g transform="translate(346, 18)">
                            <path d="M12,10 L12,9 C12,8.33333333 11.6666667,8 11,8 C10.3333333,8 9.33333333,8 8,8 C7.33333333,7.33333333 7,6.66666667 7,6 C7,5 9,3 9,2 C9,1 8,0 6,0 C4,0 3,1 3,2 C3,3 5,5 5,6 C5,6.66666667 4.66666667,7.33333333 4,8 C2.66666667,8 1.66666667,8 1,8 C0.333333333,8 0,8.33333333 0,9 L0,10 L6,10 L12,10 Z"></path>
                            <path d="M1,10 L1,12 L11,12 C11,10.6666667 11,10 11,10 C11,10 7.66666667,10 1,10 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconVOA;
