import React, { useState, useEffect, useMemo } from 'react';
import styles from  './full-visa-list.module.scss';
import PopUpContentVisaList from '../common/popup/popup-content-visa-list';
import useVisaData from '../../hooks/useVisaData';
import useData from '../../hooks/useData';
import LoadingSpinner from '../common/loading/loading-spinner';
import { getTotalVisaListOfCategory } from '../../utils/convert';
import { visaGroups } from '../../config/project-config';
import bakground from '../../assets/img/background-blur.png';
import CloseButton from '../common/button/button-close';

const FullVisaListPerCountry = ({countryCode}) => {

    const {visaData, fetchVisaData, visaDataLoading, visaDataError} = useVisaData();
    const {countries, loading, error} = useData();
    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        const fetchAndSetDefaultCountry = async () => {
          if (countryCode) {
              await fetchVisaData(countryCode);
          }
        };
      
        fetchAndSetDefaultCountry();
    }, [countryCode, fetchVisaData]);

    useEffect(() => {
      const fetchAndSetDefaultCountry = async () => {
        if (countries && countryCode) {
          const defaultCountry = countries.filter(country => country.code.toLowerCase() === countryCode.toLowerCase());
    
          if (defaultCountry[0]) {
              setSelectedCountry(defaultCountry[0]);
          }
        }
      };
    
      fetchAndSetDefaultCountry();
  }, [countryCode, countries]);


    const visaTotalMergedList = useMemo(() => {

        // Check if visaData is empty or undefined
        if (!visaData || Object.keys(visaData).length === 0) {
          // Return an empty array or some default value
          return [];
        }
  
        let visaFreeList = getTotalVisaListOfCategory(visaData,visaGroups.visa_free.categories, 'visa_free');
        let visaRequiredList = getTotalVisaListOfCategory(visaData,visaGroups.visa_required.categories, 'visa_required'); 
        let mergedList = [...visaFreeList, ...visaRequiredList];
  
        // Sort the merged array alphabetically based on 'name'.
        mergedList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      
        // Removed the console.log for production code. Keep it if this is for debugging purposes.
        // console.log(mergedList);
      
        return mergedList;
     
      },[visaData]);

    if((visaDataLoading) || (!selectedCountry && loading)) return <LoadingSpinner />;
    if(!visaData && !selectedCountry) return 'No Data Found';
    if(visaDataError || error) return "Sorry wrong request";

    return (
      <div className={styles.component_wrapper}>
        <div className={styles.background}>
          <img src={bakground} alt={visaData.country}/>
        </div>
        <div className={styles.wrapper}>
          <CloseButton linkUrl={`https://www.henleyglobal.com/passport-index?countrycode=${countryCode}`} />
          <PopUpContentVisaList
              country={`${visaData.country} Full Visa List`}
              code={visaData.code}
              list={visaTotalMergedList}
              fileUrl={selectedCountry.visa_full_url || ''}
          />
        </div>
      </div>
    );
  }
  
  export default FullVisaListPerCountry;