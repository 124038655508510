import { useEffect, useState } from 'react';
import { sortDataByName } from '../utils/utils';
// import axios from 'axios';
import { apiService } from '../services/api';

const useData = () => {
  const [currentYear, setCurrentYear] = useState('');
  const [yearsOptions, setYearsOptions] = useState([]);
  const [rankUrls, setRankUrls] = useState([]);
  const [countries, setCountries] = useState([]);
  const [passportOptions, setPassportOptions]= useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // const yearsResponse = await axios.get('/api/years-options.json');
        const yearsResponse = await apiService.get('/year-options');
        const countriesResponse = await apiService.get('/countries');
        const rankUrlsResponse = await apiService.get('/global-ranking-map');
        // const staticCountriesResponse = await axios.get('/api/countries.json');
        // const visasResponse = await axios.get('/api/visas.json');

        const yearsOptions = yearsResponse.data.years.reverse().map((year) =>({
          label: year,
          value: year,
        }));

        const sortedCountiesData = sortDataByName(countriesResponse.data.countries, 'country').filter((country) => country.has_data === true);

        // console.log(sortedCountiesData);

        const passportOptions = sortedCountiesData.map((country) =>({
          label: country.country,
          value: country.code,
          type: "passport"
        }));

        setCurrentYear(yearsResponse.data.currentYear);
        setYearsOptions(yearsOptions);
        setCountries(sortedCountiesData);
        setPassportOptions(passportOptions);
        setRankUrls(rankUrlsResponse.data);
        // setVisas(visasResponse);

      } catch (error) {
        setError('Failed to fetch visa data');
        console.error(error); // Log the error for debugging purposes
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { currentYear, yearsOptions, countries, passportOptions, rankUrls, loading, error};
};

export default useData;