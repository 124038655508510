const  IconView= () => {

    return (
        <svg width="12px" height="7.5px" viewBox="0 0 12 7.5">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1182, -733)" fill="currentColor" fillRule="nonzero">
                    <g transform="translate(1140, 502)">
                        <g transform="translate(20, 204.5)">
                            <g transform="translate(22, 26.5)">
                                <circle cx="6" cy="3.75" r="2.25"></circle>
                                <path d="M6,0 C2.25,0 0,3.72675 0,3.72675 C0,3.72675 2.25,7.5 6,7.5 C9.75,7.5 12,3.75 12,3.75 C12,3.75 9.75,0 6,0 Z M6,6.75 C2.71875,6.75 0.9375,3.75 0.9375,3.75 C0.9375,3.75 2.71875,0.75 6,0.75 C9.28125,0.75 11.0625,3.75 11.0625,3.75 C11.0625,3.75 9.28125,6.75 6,6.75 Z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconView;