import React from 'react';
import styles from "./map-tooltip.module.scss";

const MapToolTip = ({top, left, children}) => {


    return ( 
            <div className = {styles.container} style = {{top: top,left: left}} >
                <div className = {styles.container_inner} >
                    <div className = {styles.content} > 
                        {children}
                    </div>
                    
                </div> 
            </div>
        )
}

export default MapToolTip;