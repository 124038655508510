const IconSectionGlobalRank = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd" stroke="#405363" transform="translate(3 11.6)">
                <line x2="34" y1="16.9" y2="16.9" strokeLinecap="square"/><rect width="5" height="11" x="4.5" y="5.9"/>
                <rect width="5" height="16" x="13.5" y=".9"/><polyline points="10 5.9 11 5.9 11 16.6"/>
                <rect width="5" height="8" x="22.5" y="8.9"/><polyline points="19 .9 20 .9 20 16.9"/>
                <polyline points="28 8.9 29 8.9 29 16.9"/>
            </g>
        </svg>

    );

}

export default IconSectionGlobalRank;
