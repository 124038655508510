import axios from 'axios';

let baseApiUrl = "https://api.henleypassportindex.com/api/";

if (window['__hpi_url__'] !== undefined) {
    baseApiUrl = window['__hpi_url__'];
}

// 24h cache
const CACHE_TIME = 24 * 60 * 60 * 1000;

class ApiService {
  constructor(apiInstance, cacheExpirationTime = CACHE_TIME) {
    this.apiInstance = apiInstance;
    this.cacheExpirationTime = cacheExpirationTime;
  }

  /**
   * @param {String} endpoint for example '/passport'
   * @returns response from server or cached response from local storage
   */
  get = async (endpoint = '') => {
    const localStorageKey = endpoint.split('/').join('');
    //enable on production
    //let localStorageData = localStorage.getItem(localStorageKey);
    let localStorageData = null;

    if (localStorageData) {
      localStorageData = JSON.parse(localStorageData);
      const { timestamp, data } = localStorageData;
      const currentTime = new Date().getTime();

      if (currentTime - timestamp < this.cacheExpirationTime) {
        return data; // Return cached data if it's still valid
      }
    }

    // Fetch data from the API if cache has expired or no cached data
    const response = await this.apiInstance.get(endpoint);

    // Update localStorage with new data and timestamp
    localStorage.setItem(localStorageKey, JSON.stringify({
      data: response,
      timestamp: new Date().getTime(),
    }));

    return response;
  }

  post = (endpoint = '', data = {}) => {
    return this.apiInstance.post(endpoint, data);
  }
}

const api = axios.create({
  baseURL: `${baseApiUrl}v3`
});

const ultimateApi = axios.create({
  baseURL: `${baseApiUrl}v2/ultimate-portfolio`
});

const improvePassportApi = axios.create({
  baseURL: `${baseApiUrl}v2/hpi`
});

const downloadRankingApi = axios.create({
  baseURL: '/api/hpi'
});

export const apiService = new ApiService(api);
export const ultimateApiService = new ApiService(ultimateApi);
export const improvePassportApiService = new ApiService(improvePassportApi);
// Do not cache this service, it needs to be fresh every time
export const downloadRankingApiService = new ApiService(downloadRankingApi, 0);