import React from 'react';
import styles from "./map-legends.module.scss";
import IconVOA from '../../icon/icon-voa';
import IconETA from '../../icon/icon-eta';
import IconEVisa from '../../icon/icon-evisa';
import IconVisaFree from '../../icon/icon-visa-free';
import IconVisaRequired from '../../icon/icon-visa-required';


const MapLegends = () =>{

    return(

        <div className={styles.map_legend_container}>
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className={`row mx-0 ${styles.map_legend} ${styles.map_legend_visa_free}`}>
                        <div className={`col-12 col-md-4 px-0 d-flex flex-row align-items-center ${styles.label}`}>
                            <span className={styles.label_icon}>
                                <IconVisaFree />
                            </span>
                            <span className={styles.label_text}>
                                VISA-FREE <br />DESTINATIONS
                            </span>
                        </div>
                        <div className={`col-12 col-md-4 px-0 d-flex flex-row align-items-center ${styles.label}`}>
                            <span className={styles.label_icon}>
                                <IconVOA />
                            </span>
                            <span className={styles.label_text}>
                                VISA ON ARRIVAL <br />(VOA)
                            </span>
                        </div>
                        <div className={`col-12 col-md-4 px-0 d-flex flex-row align-items-center ${styles.label}`}>
                            <span className={styles.label_icon}>
                                <IconETA />
                            </span>
                            <span className={styles.label_text}>
                                ELECTRONIC TRAVEL <br />AUTHORITY (ETA)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12">
                    <div className={`row mx-0 ${styles.map_legend} ${styles.map_legend_visa_required}`}>
                        <div className={`col-12 col-md-6 px-0 d-flex flex-row align-items-center ${styles.label}`}>
                            <span className={styles.label_icon}>
                                <IconVisaRequired />
                            </span>
                            <span className={styles.label_text}>
                                Visa Required <br />DESTINATIONS
                            </span>
                        </div>
                        <div className={`col-12 col-md-6 px-0 d-flex flex-row align-items-center ${styles.label}`}>
                            <span className={styles.label_icon}>
                                <IconEVisa />
                            </span>
                            <span className={styles.label_text}>
                                ELECTRONIC VISA <br />(E-VISA)
                            </span>
                        </div>
                    </div>
                </div>
            </div>
           
          
        </div>
      
    )
}

export default MapLegends;