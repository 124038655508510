import React from 'react';
import styles from "./map-controls.module.scss";

const MapControls = ({
resetZoom,
zoomIncrement,
zoomDecrement
}) =>{


    return(
        <div className={styles.mapControls}>
            <div
            className={styles.mapZoomButton}
            // onClick={() => zoomIncrement(0.5)}
            onClick={zoomIncrement}
            >
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 17V30H17V17H30V13H17V0H13V13H0V17H13Z"
                    fill="currentColor"
                    />
                </svg>
            </div>
            <div
            className={styles.mapZoomButton}
            // onClick={() => zoomIncrement(-0.5)}
            onClick={zoomDecrement}
            >
                <svg width="30" height="4" viewBox="0 0 30 4" fill="none">
                    <rect width="30" height="4" fill="currentColor" />
                </svg>
            </div>
            <div
            className={styles.mapZoomButton}
            // onClick={() => resetZoom()}
            onClick={resetZoom}
            title="Zoom to world"
            >
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                    fill="currentColor"
                    d="M12 .387085C5.37097.387085 0 5.75805 0 12.3871c0 6.629 5.37097 12 12 12 6.629 0 12-5.371 12-12C24 5.75805 18.629.387085 12 .387085Zm9.3484 7.354835h-3.9919c-.4355-2.14838-1.1662-3.97742-2.0904-5.27903 2.6613.88065 4.8484 2.80161 6.0823 5.27903Zm-5.0903 4.64518c0 1.108-.0775 2.1387-.2081 3.0968h-8.1c-.13065-.9581-.20806-1.9888-.20806-3.0968 0-1.1081.07741-2.1387.20806-3.09679h8.1c.1306.95809.2081 1.98869.2081 3.09679ZM12 1.93547c1.3016 0 2.971 2.13387 3.779 5.80645H8.22097C9.02903 4.06934 10.6984 1.93547 12 1.93547Zm-3.26613.52742C7.81452 3.75967 7.07903 5.5887 6.64355 7.74192H2.65161C3.88548 5.2645 6.07258 3.34354 8.73387 2.46289ZM1.54839 12.3871c0-1.079.16451-2.1194.46935-3.09679h4.37903c-.1258.99189-.20322 2.02259-.20322 3.09679 0 1.0742.07258 2.1048.20322 3.0968H2.01774c-.30484-.9775-.46935-2.0178-.46935-3.0968Zm1.10322 4.6451h3.99194c.43548 2.1484 1.16613 3.9775 2.09032 5.2791-2.66129-.8807-4.84839-2.8016-6.08226-5.2791ZM12 22.8387c-1.3016 0-2.97097-2.1339-3.77903-5.8065H15.779c-.808 3.6726-2.4774 5.8065-3.779 5.8065Zm3.2661-.5274c.9194-1.2968 1.6549-3.1258 2.0904-5.2791h3.9919c-1.2339 2.4775-3.421 4.3984-6.0823 5.2791Zm2.3371-6.8274c.1258-.992.2033-2.0226.2033-3.0968 0-1.0742-.0726-2.1049-.2033-3.09679h4.3791c.3048.97739.4693 2.01779.4693 3.09679 0 1.079-.1645 2.1193-.4693 3.0968h-4.3791Z"
                    ></path>
                </svg>
            </div>
        </div>
    )
}

export default MapControls;