const IconRemove = () =>{
    return(
        <svg width="14px" height="10px" viewBox="0 0 14 10">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-229.25, -19)">
                    <rect fill="#DFE1E2" x="0" y="0" width="260" height="96"></rect>
                    <g stroke="#405363" transform="translate(229.25, 19)">
                        <path d="M5.75,3 L9.75,7 M5.75,7 L9.75,3"></path>
                        <path d="M13.5,0.5 L13.5,9.5 L2.65176485,9.5 L0.551764845,5 L2.65176485,0.5 L13.5,0.5 Z"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IconRemove;