const  IconLinkedIn= () => {

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon fill="#405363" fillRule="nonzero" points="4.7241716 13.4699319 2.15195544 13.4699319 2.15195544 5.73107888 4.7241716 5.73107888"></polygon>
                <path d="M3.4382358,4.67391689 L3.42100729,4.67391689 C2.55820364,4.67391689 2,4.07952171 2,3.33695845 C2,2.57785549 2.57543215,2 3.45511974,2 C4.3351519,2 4.8764716,2.57785549 4.89370011,3.33695845 C4.89370011,4.07952171 4.33480733,4.67391689 3.4382358,4.67391689 Z" fill="#405363" fillRule="nonzero"></path>
                <path d="M13.9996554,13.4699319 L11.4274393,13.4699319 L11.4274393,9.32880649 C11.4274393,8.28887335 11.0553035,7.5790449 10.1246195,7.5790449 C9.41411589,7.5790449 8.99098375,8.05766224 8.8045713,8.52008446 C8.73669098,8.68513681 8.72015161,8.91600334 8.72015161,9.14755903 L8.72015161,13.4695873 L6.14793545,13.4695873 C6.14793545,13.4695873 6.18170333,6.45606872 6.14793545,5.7307343 L8.72015161,5.7307343 L8.72015161,6.82614433 C9.0619652,6.29894164 9.67323264,5.54810854 11.0387641,5.54810854 C12.7309481,5.54810854 14,6.65454502 14,9.03178119 L14,13.4699319 L13.9996554,13.4699319 Z" fill="#405363" fillRule="nonzero"></path>
            </g>
        </svg>
    )

}

export default IconLinkedIn;