import React from "react";
import Select, {components} from 'react-select';
import IconSearch from "../icon/icon-search";
import { useIsMobile} from "../../../hooks/mobile";
import { flag } from "../../../utils/utils";
import styles from './react-select-custom.module.scss';
import IconArrowDown from "../icon/icon-arrow-down";


const SelectCustom = ({
  value, 
  options, 
  onChange, 
  onBlur, 
  placeholder, 
  isClearable,
  isSearchable,
  darkTheme = false,
  inputControlBorderBottom = '1px solid #979797',
  showDropdownIndicator = true,
  selectType,
  otherCustomComponents,
  ...props
}) => {

    const isMobile = useIsMobile();

    const dropDownIndicator = (props) => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              {isSearchable ? <IconSearch /> : <IconArrowDown />}
            </components.DropdownIndicator>
          )
        );
    };

    // const singleValueCustom = (props) =>{

    //   return(
    //     <components.SingleValue {...props}>
    //       <IconArrowDown />
    //       {props.children}
    //     </components.SingleValue>
    //   )
    // }

    // const valueContainerCustom = (props) =>{
    //   return(
    //     <components.ValueContainer {...props}>
    //       {/* <IconSearch /> */}
    //       <div>{props.children}</div>
    //     </components.ValueContainer>
    //   )
    // }



    const optionCustom = (props) => {
      
          const flagUrl = flag(props.data.value);

          return(
            <components.Option {...props}>
                <div className={styles.optionContent}>
                  {selectType === "passport" && (
                      <img src={flagUrl} style={{ height: '24px', width: '24px', borderRadius: '50%', marginRight: '10px', border:'1px solid #C1C5C8' }} alt={props.data.label} />
                  )}
                  <span className={styles.optionText}>{props.data.label} </span>
                </div>
            </components.Option>
          );
    };

    const selectStyles = {
        menu: (provided) => ({
          ...provided,
          zIndex: 100,
          backgroundColor:'#fff',
          borderRadius: 0,
          paddingLeft: 10,
          paddingRight: 10
        }),
        input: (provided) => ({
          ...provided,
          color: darkTheme ? "#C1C5C8" : "#405363",
        }),
        control: (provided) => ({
          ...provided,
          background: "transparent",
          border: "none",
          boxShadow: "none",
          borderRadius: 0,
          color: darkTheme ? "#C1C5C6" : "#405363",
          padding:0,
          borderBottom: inputControlBorderBottom,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: darkTheme ? "#C1C5C8" : "#405363",
          padding: isMobile ? 14 : 12,
          display: showDropdownIndicator ? '' : 'none'
        }),
        placeholder: (provided) => ({
          ...provided,
          color: darkTheme ? "#C1C5C8" : "#405363",
          fontFamily: "Garamond Regular",
          fontSize: isMobile ? 14: 20,
        //   paddingLeft: 16,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: darkTheme ? "#C1C5C8" : "#405363",
          fontFamily: "Garamond Regular",
          fontSize: isMobile ? 14 : 20,
        //   paddingLeft: 10,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: "0 0 8px 0",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          background: "transparent",
          color: "#C1C5C8",
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: isMobile ? 14 : 18,
          backgroundColor: state.isFocused
            ? "#C1C5C8"
            : state.isDisabled
            ? "#f3f3f3"
            : null,
          color: state.isFocused
            ? "#405363"
            : state.isDisabled
            ? "#405363"
            : "",
          opacity: state.isDisabled
            ? "0.5"
            : "1",
          cursor: state.isDisabled
            ? 'not-allowed'
            : 'pointer'
        }),
    };

    const customFilterOption = (option, rawInput) => {
      const words = rawInput.split(' ');

      const optionsMap = {
       "türkiye" : "turkiye",
       //"st. helena" : "Helena",
       //"st. kitts and nevis" : "Kitts and Nevis",
       //"st. lucia" : "Lucia",
       //"st. maarten" : "Maarten",
       //"st. vincent and the grenadines" : "Vincent and the Grenadines"
    };

    var optionLabel= option.label.toString().toLowerCase()
    if (optionsMap[optionLabel]) {
      optionLabel = optionsMap[optionLabel].toLowerCase();
    }
  
      return words.reduce(
        (acc, cur) => acc && optionLabel.startsWith(cur.toLowerCase()),
        true
      );
    };
    
    return(
        <>
            <Select
                value={value}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                isClearable={isClearable}
                isSearchable={isSearchable}
                //menuIsOpen
                placeholder={placeholder}
                components={{ DropdownIndicator:dropDownIndicator, Option: optionCustom, ...otherCustomComponents}}
                blurInputOnSelect={true}
                filterOption={customFilterOption}
                styles={selectStyles}
                {...props}
            />
        </>
    );
}

export default SelectCustom;