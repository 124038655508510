import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from "./popup.module.scss";
import CloseButton from '../button/button-close';

const PopUp = ({ isOpen, onClose, type, children }) => {
    const [popupRoot] = useState(document.createElement('div'));
    
  
    useEffect(() => {
      // Append the div to the body when the component mounts
      const body = document.body;
      body.appendChild(popupRoot);
  
      // Apply the style to disable scroll on the body
      if (isOpen) {
        body.style.overflow = 'hidden';
      }
  
      // This function will be called when the component unmounts
      return () => {
        body.removeChild(popupRoot);
        body.style.overflow = 'auto'; // Reset the overflow when the popup is removed
      };
    }, [isOpen, popupRoot]);
  
    // Don't render the portal content if the popup is not open
    if (!isOpen) return null;

    // const popupCardClass = `${styles.popupCard} ${extraClassNames.popupCard || ''}`.trim();
    const popupCardClass = `${styles.popupCard} ${type === 'graph' ? styles.popupGraphCard: styles.popupListCard }`.trim();
  
    return ReactDOM.createPortal(
      <div className={styles.popupOverlay} onClick={onClose}>
        <div className={popupCardClass} onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose} />
          {children}
        </div>
      </div>,
      popupRoot
    );
  };
  
  export default PopUp;