import { iso2ToIso3Mapping } from "./iso2-to-iso3";

export function getCountryISO3(iso2) {
    return iso2ToIso3Mapping[iso2]
}

export function getCountryISO2(iso3){
    return Object.keys(iso2ToIso3Mapping).find(key => iso2ToIso3Mapping[key] === iso3);
}


export function createPassportsList(datasets) {
    const passportOptions = datasets.filter(row => (row.iso_code !== 'JEY'))
        .map((row, index) => {
        return {
            value: getCountryISO2(row.iso_code),
            label: row.country,
            iso_code_3: row.iso_code,
            cardType: 'Passport',
            type: false,
        }
    }).sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    return passportOptions;
}

// export function createRcbiList(portfolioData) {
//     const filteredList = portfolioData.filter(row => ((row.cpi === true || row.rpi === true || row.cbn === true) && row.country !== 'Montenegro' && row.country !== 'Ireland'))
//         .map(row => {
//             return { iso_code: row.iso_code, country: row.country, cpi: row.cpi, rpi: row.rpi, cbn: row.cbn }
//         });

//     const rbiList= filteredList.map((d) => {
//         let cardType = '';
//         if(d.cpi === true && d.rpi === false){
//             cardType = 'CBI';
//         }else if(d.rpi === true && d.cpi === false){
//             cardType = 'RBI';
//         }

//         let type = '';

//         if(d.cpi === true && d.rpi === false){
//             type = 'cpi';
//         }else if(d.rpi === true && d.cpi === false){
//             type = 'rpi';
//         }

//         return {
//             value: d.iso_code + '-' + cardType,
//             label: d.country,
//             iso_code: d.iso_code,
//             cardType:cardType,
//             type: type
//         };
//     });

//     const cbnList = filteredList.filter((d)=>{
//         return d.cbn === true;
//     }).map((d)=>{
//         return {value: d.iso_code + '-CBN', label: d.country, iso_code: d.iso_code, cardType: 'CBN', type: 'cbn'}
//     });

//     const combinedList = rbiList.concat(cbnList);

//     const sortedList = combinedList.sort((a, b) =>
//         a.label.localeCompare(b.label)
//     );

//     return sortedList;
// }

export function createRbiList(portfolioData) {
    const filteredList = portfolioData.filter(row => (row.rpi === true ))
        .map(row => {
            return {
                value: getCountryISO2(row.iso_code),
                label: row.country + ' Residence',
                iso_code_3: row.iso_code,
                cardType: 'RPI',
                type:'rpi'
            }
        })
        .sort((a, b) =>
            a.label.localeCompare(b.label)
        );

    return filteredList;
}

export function createCbiList(portfolioData) {
    const filteredList = portfolioData.filter(row => (row.cpi === true || row.cbn === true))
        .map(row => {
            let cardType = '';
            let type = '';
            if(row.cpi === true && row.cbn === false){
                cardType = 'CBI';
                type = 'cbi';
            }else if((row.cpi === false && row.cbn === true)){
                cardType = 'CBN';
                type = 'cnb';
            }
            return {
                value: getCountryISO2(row.iso_code),
                iso_code_3: row.iso_code,
                label: row.country,
                cardType: cardType,
                type: type,
            }
        })
        .sort((a, b) =>
            a.label.localeCompare(b.label)
        );

    return filteredList;
}


export function calculatePortfolioOverview(portfolioData, country) {
    // console.log(country);
    let response = {};
    // const freeVisaCountries = calculateFreeVisaCountries([country]);
    // find the overview data for a country by its iso code
    const found = portfolioData.find(row => row.iso_code.toUpperCase() === country.iso_code.toUpperCase());
    if (found) {
        const overall = found.business_opportunity + found.climate_resilience + found.economic_mobility + found.education + found.lifestyle + found.private_healthcare + found.safety_security;
        const minimumInvestmentUSD = (country.type === false) ? 0 : found.minimum_investment[country.type]?.usd || 0;
        const minimumInvestmentEUR = (country.type === false) ? 0 : found.minimum_investment[country.type]?.eur || 0;
        response = {
            iso_code: found.iso_code,
            country: found.country,
            overall: overall,
            businessOpportunity: found.business_opportunity,
            climateResilience: found.climate_resilience,
            economicFreedom: found.economic_freedom,
            economicMobility: found.economic_mobility,
            education: found.education,
            lifestyle: found.lifestyle,
            privateHealthcare: found.private_healthcare,
            safetyAndSecurity: found.safety_security,
            minimumInvestmentUSD: minimumInvestmentUSD,
            minimumInvestmentEUR: minimumInvestmentEUR,
            // freeVisaCountries: freeVisaCountries,
            // freeVisaCountriesCount: freeVisaCountries.length
        }
    }
    return response;
  }

  export function calculateSingleFreeVisaCountries(passportData, country) {

    const found = passportData.find(row => row.iso_code.toUpperCase() === country.iso_code.toUpperCase());

    const visaFreeISO3List = country.type === 'rpi' ? found.rpi_free_visa_countries : found.hpi_free_visa_countries;

    const rank = found.rank;
    const free_visa_url = found.free_visa_url;
    // const free_visa_url =
    // console.log(rank);

    const passportLookup = passportData.reduce((obj, item) => {
        obj[item.iso_code] = item.country;
        return obj;
    }, {});

    const freeVisaCountries = visaFreeISO3List.map(code => {
        return {
          "iso_code_3": code,
          "code": getCountryISO2(code),
          "name": passportLookup[code] || 'Unknown', // Use 'Unknown' for missing codes
          "groupName": 'visa_free',
        };
    });

    return {freeVisaCountries, rank, free_visa_url};
  }

  export function formatImproveResult(response){

    const freeVisaCount = response.visa_free_count;
    const freeVisaCountries = Object.values(response.visa_free).map(visa => {
        return {
          "iso_code_3": visa.code,
          "code": getCountryISO2(visa.code),
          "name": visa.name,
          "groupName": 'visa_free',
        };
    });

    const results = {
      freeVisaCount: freeVisaCount,
      freeVisaCountries: freeVisaCountries,
    }
    return results;
}
