import React from 'react';
import styles from "./loading-spinner.module.scss";


const LoadingSpinner = () =>{


    return( 
        <div className={styles.loadingContainer}>
            <div className={styles.ldsEllipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    )
}

export default LoadingSpinner;