import React, { useEffect, useRef } from 'react';
import ReactEcharts from "echarts-for-react"; 
import styles from "./popup.module.scss";
import CountryFlag from '../country-flag/country-flag';
import { getNumberWithOrdinal, getNumberWithOrdinalWithSuperscript, renderHTML } from '../../../utils/utils';

const PopUpContentGraph = ({country, code, data, isInitialLoad, contentType}) =>{
    const echartsRef = useRef();
    // const chartContainerRef = useRef(null);
    // const [chartHeight, setChartHeight] = useState('300px'); // A default height

    // useEffect(() => {
    //     if (isInitialLoad && chartContainerRef.current) {
    //       const resizeObserver = new ResizeObserver(entries => {
    //         if (entries[0].target.clientHeight !== 0) {
    //           setChartHeight(`${entries[0].target.clientHeight}px`);
    //         }
    //       });
    
    //       resizeObserver.observe(chartContainerRef.current);
    
    //       // Cleanup the observer
    //       return () => resizeObserver.disconnect();
    //     }
    //   }, [isInitialLoad]);
    useEffect(() => {
        if (isInitialLoad) {
          // We're using a timeout to allow the popup to render
          const timer = setTimeout(() => {
            const echartInstance = echartsRef.current.getEchartsInstance();
            echartInstance.resize();
          }, 0); // This delay can be adjusted or even removed depending on your modal's behavior
    
          return () => clearTimeout(timer);
        }
      }, [isInitialLoad]);

    const dataSet = () =>{
        let newArr = [];
        for(const key in data.data){
            let obj = [];
            obj = {
                x: key,
                y: data.data[key].rank
            }
            newArr.push(obj);
        }

        return newArr;
    }

    const getDataSet = (key) =>{
        let newArr = [];
        dataSet().map((item) => newArr.push(item[key]));
        return newArr;
    }

    const maxValue = Math.max(...getDataSet('y'));
    const minValue = Math.min(...getDataSet('y'));
   
    const maxY = Math.ceil((maxValue + 10) / 5 ) * 5;
    const minY = minValue - 10 > 0 ? minValue - 10 : 1;
    // const splitNumber = Math.round(Math.ceil( (maxY - minY) / 3 ));
    // const middleY = (minY + maxY) /2;
   

    const option = {
        xAxis: {
          type: 'category',
          data: getDataSet('x'),
          boundaryGap: true,
        //   onZero:false,
          axisLine:{
            lineStyle:{
                color: '#DFE1E2'
            }
          },
          axisLabel:{
            color: '#405363',
            fontWeight: 'normal',
            fontFamily:'Roboto Medium'
          },
          axisTick:{
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: true,
        //   onZero: false,
          max: maxY,
          min: minY,
          inverse: true,
          scale: true,
        //   splitNumber: splitNumber, 
        //   data:[minY, middleY, maxY],
          axisLabel:{
            // formatter: function(d) {
            //     if(d === 200){
            //         return 1;
            //     }else if(d === 1){
            //         return 200;
            //     }else{
            //         return 200 - d;
            //     }
            // },
            show: false,
            color: '#405363',
            fontWeight: 'normal',
            fontFamily:'Roboto Medium'
          }
        },
        series: [
          {
            data: getDataSet('y'),
            type: 'line',
            symbol: 'rect',
            symbolSize: 12,
            smooth: false,
            lineStyle:{
                color: '#405363',
                width: 1,
            },
            itemStyle: {
                borderWidth: 1,
                borderColor: '#405363',
                color: '#405363',
            },
            emphasis:{
                itemStyle:{
                    color:'#405363'
                }
            },
            label:{
                show:true,
                position:'top',
                formatter:function(d) {
                    return getNumberWithOrdinal(d.data);
                },
                fontSize: 11,
                color: '#405363',
                fontWeight: 'bold',
                fontFamily:'Roboto Medium',
                borderColor:'#cccccc',
                borderType:'solid',
                backgroundColor:'#ffffff',
                borderWidth: 2,
                padding:3
            }
          }
        ],
        grid: {
            containLabel:false,
            left: "5%",
            right: "5%",
            borderColor: '#DFE1E2',
            borderWidth: 1,
        },
        tooltip:{
            show: false,
            // formatter: function(params){
            //     return getNumberWithOrdinalWithSuperscript(200 - params.data) + ' IN ' + params.name;
            // },
            padding: 10,
            borderColor: '#C1C5C8',
            borderWidth: 1,
            textStyle:{
                color:'#405363',
                fontSize: 11,
                fontWeight: 'normal',
                fontFamily:'Roboto Bold'
            }
        //     formatter: '{c} <strong>IN {b}</strong>',
        //     valueFormatter: (value) => '$' + value.toFixed(2)
        }
    }; 
      


    return(
        <div className={`${styles.cardGraphContainer} ${contentType === 'popup' ? styles.cardGraphInPopUp : ''}`}>
            <div className={styles.cardHeader}>

                <div className={styles.countryFlagWrapper}>
                    <CountryFlag country={country} code={code} longText={true} />
                </div>
            </div>
            <div className={styles.cardBody}>
                <div className={`row align-items-stretch`}>
                    <div className={`col-md-9 mb20 mb-md-0`}>
                        <div className={styles.graphContainer}>
                            <div className={styles.graphWrapper}>
                                <ReactEcharts
                                    ref={echartsRef}
                                    option={option} 
                                    style={{
                                        height: 460,
                                        width: '100%',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className={styles.tableData}>
                            <table className={styles.tableHeader}>
                                <thead>
                                    <tr>
                                        <td>YEAR</td>
                                        <td>RANK</td>
                                    </tr>
                                </thead>
                            </table>
                            <table>
                                <tbody>
                                    {dataSet() && dataSet().map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.x}</td>
                                            <td>{renderHTML(getNumberWithOrdinalWithSuperscript(item.y))}</td>
                                        </tr>
                                    ))}
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUpContentGraph;