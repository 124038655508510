import React, { useState, useEffect, useRef} from 'react';
import styles from  './compare-passport.module.scss';
import SelectCustom from '../common/react-select-custom/select-custom';
import useData from '../../hooks/useData';
import useVisaData from '../../hooks/useVisaData';
import { getTotalVisaListOfCategory } from '../../utils/convert';
import { visaGroups } from '../../config/project-config';
import LoadingSpinner from '../common/loading/loading-spinner';
import PassportCard from "../common/passport-card/passport-card";
import PopUp from "../common/popup/popup";
import PopUpContentVisaList from "../common/popup/popup-content-visa-list";
import VisaItem from '../common/visa-item/visa-item';
import SelectVisaFreeMatch from '../common/react-select-custom/select-visa-free-match';
import PageIntro from '../common/page-intro/page-intro';
import IconSectionCompare from '../common/icon/icon-section-compare';

const ComparePassport = ({countryCode}) => {

    const {countries, currentYear, passportOptions, loading} = useData();
    const {visaData, fetchVisaData} = useVisaData();

    const [selectedPassport, setSelectedPassport] = useState('');
    const [selectedDestination, setSeletedDestination] = useState('');
    const [comparePassportData, setComparePassportData] = useState([]);
    const [compareVisaData, setCompareVisaData] = useState([]);

    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [popUpData, setPopUpData] = useState(null);

    const [isScrolled, setIsScrolled] = useState(false);
    const scrollElementRef = useRef(null);

    useEffect(() => {
      const fetchAndSetVisaData = async () => {
        if (countries && countryCode) {
          const defaultPassport = countries.filter(country => country.code.toLowerCase() === countryCode.toLowerCase());
          if (defaultPassport[0]) {

            setComparePassportData(currentData => [
              ...currentData,
              {
                ...defaultPassport[0]
              }
            ]);
            // console.log(defaultPassport[0])
            await fetchVisaData(defaultPassport[0].code);
          }
        }
      };

      fetchAndSetVisaData();
    }, [countryCode, countries, fetchVisaData]);

    useEffect(() => {
      if(visaData){
        setCompareVisaData((prevAddedVisaData) => [
          ...prevAddedVisaData,
          visaData,
        ]);
      }
    },[visaData]);

    useEffect(() => {
      const equalizeCardHeights = () => {
        let max_height = 0;
        const cards = document.querySelectorAll('.compare-card-wrapper .card-country-name');

        // Reset heights and find the tallest card
        cards.forEach(card => {
          card.style.height = 'auto';
          if (card.offsetHeight > max_height) {
            max_height = card.offsetHeight;
          }
        });

        // Set all cards to the tallest height
        cards.forEach(card => card.style.height = `${max_height}px`);
      };

      equalizeCardHeights();
      window.addEventListener('resize', equalizeCardHeights);

      // Cleanup function
      return () => {
        window.removeEventListener('resize', equalizeCardHeights);
      };
    }, [comparePassportData]);

    useEffect(() => {
      const handleScroll = () => {
        if (scrollElementRef.current) {
              const rect = scrollElementRef.current.getBoundingClientRect();
              setIsScrolled(rect.top <= 100);
          }
      };

      // Add event listener
      window.addEventListener('scroll', handleScroll);

      // Clean up the event listener
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    const handlePassportSelect = async (option) =>{
      setSelectedPassport('');
       if(option.value){
        fetchVisaData(option.value);
        const selectedPassportData = countries.find((passport) => passport.code === option.value);
        setComparePassportData([
          ...comparePassportData,
          {
            ...selectedPassportData
          }
        ]);
       }
    }

    const handleDelete = (index) =>{
      if(index === 0){
        setComparePassportData([]);
        setCompareVisaData([]);
        setSeletedDestination('');
      }else{
        setComparePassportData((prevSelectedPassports) => {
          const newSelectedPassports = [...prevSelectedPassports];
          newSelectedPassports.splice(index, 1);
          return newSelectedPassports;
        });

        setCompareVisaData((prevVisaData) => {
          const newVisaData = [...prevVisaData];
          newVisaData.splice(index, 1);
          return newVisaData;
        });
      }
    }

    const handleDestinationSelect = (option) =>{
      setSeletedDestination(option);
    }

    const handleDestinationSelectClear = () =>{
      setSeletedDestination('');
    }

    const handlePopUpOpen = (country, code, fullVisaList, fileUrl) =>{
      console.log(fileUrl);
      setIsPopUpOpen(true);
      setPopUpData({country, code, fullVisaList, fileUrl});
    }

    const handlePopUpClose = () =>{
      setIsPopUpOpen(false);
    }

    const renderSelect = () =>{
      if(comparePassportData.length < 4){
        const topText = comparePassportData.length === 0 ? 'MY PASSPORT' : "COMPARE WITH";
        return (
            <div className="col-12 col-md-3 col-lg-3">
              <div className={`${styles.card_top}`}>
                <h4 className={`text-hp-blue ${comparePassportData.length > 1 ? styles.opacity0 : ''}`}>{topText}</h4>
                <div className={`column-select bg-hp-blue p-3`}>
                    <div className="select-wrapper-inner">
                      <h4>SELECT A PASSPORT</h4>
                      <SelectCustom
                        options={passportOptions}
                        value={selectedPassport}
                        placeholder="Type here"
                        darkTheme
                        onChange={handlePassportSelect}
                        selectType="passport"
                        isSearchable
                      />
                  </div>
                </div>
              </div>
            </div>
        )
      }

      return null;
    }

    const renderSelectVisaFreeCheck = () =>{
      return(
        <SelectVisaFreeMatch
          options={passportOptions}
          selectedOption={selectedDestination}
          handleVisaFreeSelect={handleDestinationSelect}
          handleVisaFreeSelectClear={handleDestinationSelectClear}
          singleMatch={false}
          placeholder='Can I travel visa-free to?'
        />
      )
    }


    const renderComparePassportData = () =>{
      return comparePassportData && comparePassportData.map((item, index) => {

        const filteredVisaList = compareVisaData.filter((visa) => visa.code === item.code);
        // console.log(item);
        const currentCountry = {
          name: item.country,
          code: item.code,
          groupName: "visa_free",
          note: "current item",
          type: "visa_free_access"
        }
        let allVisaFreeList = [];
        let allVisaRequiredList = [];
        let fullVisaList = [];
        let visaFreeMatch = '';

        if(filteredVisaList[0]){
          allVisaFreeList = getTotalVisaListOfCategory(filteredVisaList[0], visaGroups.visa_free.categories, 'visa_free');
          allVisaRequiredList = getTotalVisaListOfCategory(filteredVisaList[0], visaGroups.visa_required.categories, 'visa_required');
          fullVisaList = [...allVisaFreeList, ...allVisaRequiredList, currentCountry].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
          if(selectedDestination){
            visaFreeMatch = allVisaFreeList.find((visa) => visa.code === selectedDestination.value)!== undefined || selectedDestination.value === item.code;
          }
          // console.log(fullVisaList);
        }

        const topText = index === 0 ? 'MY PASSPORT' : "COMPARE WITH";


        return(
          <div key={index} className={`col-12 col-md-3 col-lg-3 position-relative`}>
            <div className={`compare-card-top ${styles.card_top}`}>
              <h4 className={`text-hp-blue ${index > 1 ? styles.opacity0 : ''}`}>{topText}</h4>
              <div className="compare-card-wrapper">
                <PassportCard
                  country={item.country}
                  code={item.code}
                  rank={item.data[currentYear] ? item.data[currentYear].rank : 0}
                  visaFreeCountries={item.data[currentYear] ? item.data[currentYear].visa_free_count : 0}
                  cardType="passport"
                  handleDelete={() => handleDelete(index)}
                  visaFileButtonText="FULL VISA LIST"
                  visaFileLink={item.visa_full_url}
                  visaListButtonText="FULL VISA LIST"
                  handleVisaListClick={() => handlePopUpOpen(item.country, item.code, fullVisaList, item.visa_full_url)}
                  showButtons={!isScrolled}
                  showInfo={!isScrolled}
                  customCardClass={styles.customCardClass}
                />
              </div>

              <div className={`${styles.visaCheckWrapper} ${index === 0? styles.wrapperShadow : ''}`}>
                <div className={styles.visaCheckSelect}>
                  {index === 0 && (
                    renderSelectVisaFreeCheck()
                  )}
                </div>
                {selectedDestination && (
                    <div className={`${styles.visaCheckItem} ${index === 0 ? styles.no__border__shadow : ''}`}>
                      <VisaItem
                        country={selectedDestination.label}
                        code={selectedDestination.value}
                        visaGroupName={visaFreeMatch ? 'visa_free' : 'visa_required'}
                      />
                    </div>
                )}
              </div>
              <div className={styles.fullVisaListHeader}>
                    Full Visa List
              </div>
            </div>
            <div className={styles.fullVisaList}>
               <div className={styles.fullVisaList__labels}>
                    <div className="row">
                      <span className={`col-6 d-inline-flex align-items-center ${styles.cardHeaderIconWrapper}`}>
                          <span className={`${styles.cardHeaderIcon} mr-1`}  style={{backgroundColor: visaGroups["visa_free"].color, color:'#fff'}}>
                          {visaGroups["visa_free"].icon}
                          </span>
                          VISA-FREE
                      </span>
                      <span className={`col-6 d-inline-flex align-items-center ${styles.cardHeaderIconWrapper}`}>
                      <span className={`${styles.cardHeaderIcon} mr-1`}  style={{backgroundColor: visaGroups["visa_required"].color, color:'rgb(64, 83, 99)'}}>
                      {visaGroups["visa_required"].icon}
                      </span>
                      VISA REQUIRED
                      </span>
                    </div>
              </div>
              <ul>
                {fullVisaList && fullVisaList.map((visa, index) => (
                <li key={index}>
                    <VisaItem
                        country={visa.name}
                        code={visa.code}
                        visaGroupName={visa.groupName}
                        isCurrentItem={visa.code === item.code}
                    />
                </li>
                ))}
            </ul>
            </div>

          </div>
        )
      })
    }

    if(loading) return <LoadingSpinner />

    return (
      <div className="appContainer">

          <PageIntro
            icon={<IconSectionCompare />}
            heading='Compare My Passport'
            text={<p>Discover where your passport lies on the global mobility spectrum by comparing it with the other 198 passports and 226 destinations included on the Henley Passport Index.</p>}
          />

          <div ref={scrollElementRef} className={`row ${styles.cardWrapper} ${isScrolled ? styles.onScroll : ''}`}>
                {renderComparePassportData()}

                {renderSelect()}
          </div>

          {isPopUpOpen && (
            <PopUp
              isOpen={isPopUpOpen}
              onClose={handlePopUpClose}
            >

              <PopUpContentVisaList
                country={`${popUpData.country} Full Visa List`}
                code={popUpData.code}
                list={popUpData.fullVisaList}
                fileUrl={popUpData.fileUrl}
              />

            </PopUp>
          )}

       </div>

    );
  }

  export default ComparePassport;