const IconHome = () =>{
    return(
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon fill="#405363" fillRule="nonzero" points="8 1.34914586 8.3200922 1.61588936 14.3200922 6.61588936 13.6799078 7.38411064 8 2.651 2.3200922 7.38411064 1.6799078 6.61588936 7.6799078 1.61588936"></polygon>
                <polygon fill="#405363" fillRule="nonzero" points="4.5 7 4.5 13.5 11.5 13.5 11.5 7 12.5 7 12.5 14.5 3.5 14.5 3.5 7"></polygon>
                <polygon fill="#405363" fillRule="nonzero" points="13.5 1.5 13.5 4 12.5 4 12.5 2.5 11 2.5 11 1.5"></polygon>
            </g>
        </svg>
    )
}

export default IconHome;