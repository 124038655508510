import React from 'react';
import styles from "./button.module.scss";
import IconClose from "../icon/icon-close"


const CloseButton = ({onClick, linkUrl}) =>{

    if(linkUrl){
        return (
            <a onClick={onClick} className={styles.closeButton} href={linkUrl} rel="noreferrer">
                <IconClose />
            </a>
        )
    }

    return(
        <button onClick={onClick} className={styles.closeButton}>
            <IconClose />
        </button>
    )
}

export default CloseButton;