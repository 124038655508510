const  IconVisaRequired = () => {

    return (
        <svg width="12px" height="12px" viewBox="0 0 12 12">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-346, -166)" stroke="currentColor" strokeWidth="2">
                    <g transform="translate(0, 148)">
                        <g transform="translate(346, 18)">
                            <path d="M0,0 L12,12 M0,12 L12,0"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconVisaRequired;
