import React, {useState, useEffect,  useRef, Fragment} from "react";
import styles from "./improve-passport.module.scss";
import { apiService, ultimateApiService, improvePassportApiService } from "../../services/api";
import SelectCustom from "../common/react-select-custom/select-custom";
import SelectVisaFreeMatch from "../common/react-select-custom/select-visa-free-match";
import { createPassportsList, createRbiList, createCbiList, calculateSingleFreeVisaCountries, formatImproveResult} from "../../utils/improve";
import { convertToObjectAndRemoveEmpty} from "../../utils/utils";
import LoadingSpinner from "../common/loading/loading-spinner";
import PopUp from "../common/popup/popup";
import PopUpContentVisaList from "../common/popup/popup-content-visa-list";
import Button from "../common/button/button";
import PassportCard from "../common/passport-card/passport-card";
import PageIntro from "../common/page-intro/page-intro";
import IconSectionImprove from '../common/icon/icon-section-improve';

const ImprovePassport = ({countryCode}) => {

  const [portfolioData, setPortfolioData] = useState(null);
  const [passportData, setPassportData] = useState(null);
  const [passportOpts, setPassportOpts] = useState(null);
  const [cbiOpts, setCbiOpts] = useState(null);
  const [rbiOpts, setRbiOpts] = useState(null);

  const [selectedPassport, setSelectedPassport]= useState({0:'', 1: ''});
  const [selectedProgram, setSelectedProgram] = useState({0:'', 1: ''});

  const [resultData, setResultData] = useState(null);
  const [resultError, setResultError] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);
  const [selectedTotalMatch, setSelectedTotalMatch] = useState('');
  const resultRef = useRef(null);

  const ADDPASSPORT_BUTTONS_TOTAL = 2;

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [popUpData, setPopUpData] = useState(null);
  const [showPopUpCountryHeader, setShowPopUpCountryHeader] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const countriesResponse = await apiService.get('/countries');
      const responsePortfolio = await ultimateApiService.get("/portfolio");
      const responsePassport = await improvePassportApiService.get("/passport");

      setPortfolioData(responsePortfolio.data);
      setPassportData(responsePassport.data);

      const countriesData = countriesResponse.data.countries;
      let passportData = responsePassport.data.map((item) => {
        const country = countriesData.find((country) => item.country === country.country)
        return {
          ...item,
          hasData: country.has_data,
        }
      });

      passportData = passportData.filter((country) => country.hasData === true);
      const passportOptions = createPassportsList(passportData);
      setPassportOpts(passportOptions);
      // setRcbiOpts(createRbiList(responsePortfolio.data));
      setRbiOpts(createRbiList(responsePortfolio.data));
      setCbiOpts(createCbiList(responsePortfolio.data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchAndSetDefaultCountry = () => {
      if (passportOpts && countryCode) {
        const defaultCountry = passportOpts.find(country => country.value.toLowerCase() === countryCode.toLowerCase());

        if (defaultCountry) {
          const defaultInfo = calculateSingleFreeVisaCountries(passportData, {iso_code: defaultCountry.iso_code_3, type: defaultCountry.type});
          const obj = {
            value:defaultCountry.value,
            label:defaultCountry.label,
            iso_code: defaultCountry.iso_code_3,
            type: defaultCountry.type,
            freeVisaCountries: defaultInfo.freeVisaCountries,
            rank: defaultInfo.rank,
            free_visa_url: defaultInfo.free_visa_url
          }

          setSelectedPassport({0: obj, 1:''});
        }
      }
    };

    fetchAndSetDefaultCountry();
  }, [countryCode, passportData, passportOpts]);

  useEffect(() => {

    async function resultData() {
      const convertedPassportData = convertToObjectAndRemoveEmpty(selectedPassport);
      const convertedProgramData = convertToObjectAndRemoveEmpty(selectedProgram);

      if((convertedPassportData.length + convertedProgramData.length) >= 2){
          setResultLoading(true);
          const formdata = new FormData();

          for (let i = 0; i< convertedPassportData.length; i++){
              formdata.append("passports[]", convertedPassportData[i].iso_code)
          }

          for (let j = 0; j< convertedProgramData.length; j++){
              formdata.append("new_passports[]", convertedProgramData[j].iso_code);
              if(convertedProgramData[j].type === 'rpi'){
                  formdata.append(`new_passports_types[${j}]`, "rpi");
              }
          }

          try {
            const response = await ultimateApiService.post("/calculator", formdata);
            const formatReults = formatImproveResult(response.data);

            setResultData(formatReults);
            setResultError(false);
          } catch(err){
            console.log(err);
            setResultData(null);
            setResultError(true);
          } finally{
            setResultLoading(false);
          }
      } else {
          setResultData(null);
      }
    }

    resultData();
  }, [selectedPassport, selectedProgram]);

  const handleSelect = (option, index, cardType) =>{
    // console.log(option);
    // const countryData = calculatePortfolioOverview(portfolioData, {iso_code:option.iso_code_3, type:option.type});
    const passportInfo = calculateSingleFreeVisaCountries(passportData, {iso_code: option.iso_code_3, type: option.type});
    const obj = {
      value:option.value,
      label:option.label,
      iso_code: option.iso_code_3,
      type: option.type,
      freeVisaCountries: passportInfo.freeVisaCountries,
      rank: passportInfo.rank,
      free_visa_url: passportInfo.free_visa_url
    }

    if(cardType === 'passport'){
      setSelectedPassport({
        ...selectedPassport,
        [index]: obj
      });
      // console.log(option.iso_code.toUpperCase());
    } else if(cardType === 'program') {
      setSelectedProgram({
        ...selectedProgram,
        [index]: obj
      })
    }
  }

  const handleClear = (index, cardType) => {
    if(cardType === 'passport') {
      setSelectedPassport({
        ...selectedPassport,
        [index]: ''
      });
    } else if(cardType === 'program') {
      setSelectedProgram({
        ...selectedProgram,
        [index]: ''
      });
    }
  }

  const handlePopUpOpen = (data, showCountryHeader) => {
    setIsPopUpOpen(true);
    setShowPopUpCountryHeader(showCountryHeader);
    setPopUpData(data);
  }

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  }

  const handleVisaFreeSelect = (option) => {
    setSelectedTotalMatch(option);
  }

  const handleVisaFreeSelectClear = () => {
    setSelectedTotalMatch('');
  }

  const downloadVisaFreeList = () => {
    // Extract iso_code values to array list of strings ['code1', 'code2',...]
    const isoCodes = Object.values(selectedPassport).map(item => item.iso_code);
    if (window.downloadSelectedCountriesVisaFree) {
      window.downloadSelectedCountriesVisaFree(isoCodes);
    }
  }

  // const onClickToPosition = () =>{
  //   resultRef.current.scrollIntoView({ behavior: "smooth" });
  // }

  const renderSelect = (type) => {
    const length = Array.from(Array(ADDPASSPORT_BUTTONS_TOTAL).keys());

    return length.map((i) => {
      let options = passportOpts;
      let selected = selectedPassport;
      let labelText = 'SELECT A PASSPORT';
      let cardType = 'passport';
      

      if (type === 'program') {
        options = i === 0 ? cbiOpts : rbiOpts;
        selected = selectedProgram;
        labelText = i=== 0 ? 'SELECT A CITIZENSHIP PROGRAM' : 'SELECT A RESIDENCE PERMIT';
        cardType = i === 0 ? 'CBI' : 'RBI';

        
        //
      }

      let free_visa_url = selected[i].free_visa_url
        if (free_visa_url !== undefined && cardType === 'RBI') {
          free_visa_url = free_visa_url.replace(".pdf", "_rbi.pdf");
        }



      return (
        <div className="col-12 col-md-6 col-lg-3 mb20" key={`${type}-${i}`}>
          {selected[i] === '' && (
          <div className="column-select bg-hp-blue p-3">
            <div className="select-wrapper-inner">
              <h5>{labelText}</h5>
              <SelectCustom
                options={options}
                onChange={(option) => handleSelect(option, i, type)}
                value={selected[i]}
                darkTheme
                isSearchable
                selectType="passport"
                placeholder="Type here"
              />
            </div>
          </div>
          )}
          {selected[i]!== '' && (
            <PassportCard
              code={selected[i].value}
              country={selected[i].label}
              cardType={cardType}
              handleDelete={() => handleClear(i, type)}
              rank={selected[i].rank}
              visaFreeCountries={selected[i].freeVisaCountries.length}
              visaFileLink={free_visa_url}
              visaFileButtonText="VISA-FREE LIST"
              visaListButtonText="VISA-FREE LIST"
              handleVisaListClick={() => handlePopUpOpen(selected[i], true)}
            />
          )}
        </div>
      );
    });
  }

  if (!portfolioData) return <LoadingSpinner />;

  return (
    <div className="appContainer">

      <PageIntro
        heading="Improve My Global Mobility"
        icon={<IconSectionImprove />}
        text={<p>Through <a href="https://www.henleyglobal.com/residence-investment" target="_blank" rel="noreferrer">residence</a> and <a href="https://www.henleyglobal.com/citizenship-investment" target="_blank" rel="noreferrer">citizenship</a> by investment, we can help you strengthen your passport power and enhance your global mobility as well as improve your access to better business, lifestyle, education, and healthcare opportunities. Find out how to create the ultimate portfolio of complementary residence and citizenship options to secure the greatest possible global access for you and your family <a href="https://www.henleyglobal.com/ultimate-portfolio" target="_blank" rel="noreferrer">here</a>.</p>}
      />

      <div className="row flex-md-row-reverse">
        <div ref={resultRef} className="col-12 col-md-4 w-lg-20  mb50">
          <div className={styles.resultWrapper}>
            <div className={styles.resultHeader}>
              <h3 className="mb-0">My Travel Freedom</h3>
            </div>
            <div className={styles.resultContent}>
              {resultData === null && (
                <div className={styles.resultMain}>
                <p className="text-white mb-0">Please select two or more options to find out how you can enhance your global mobility.</p>
                </div>
              )}

              {resultLoading && <LoadingSpinner />}
              {resultError && (
                <div>Error request, please reselect</div>
              )}
              {resultData && !resultLoading && (
                <Fragment>
                  <div className={styles.resultMain}>
                    <p className="text-white text-uppercase">You can now travel to </p>
                    <h1 className="text-white">{resultData.freeVisaCount}</h1>
                    <p className="text-white text-uppercase">destinations visa-free</p>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <Button
                      buttonText="Full visa-free list"
                      iconStyle="eye"
                      onClick={() => handlePopUpOpen(resultData, false)}
                    />
                  {/* 
                    <Button
                      id="full-visa-free-list-download"
                      buttonText="Full visa-free list"
                      iconStyle="download"
                      onClick={downloadVisaFreeList}
                    />
              */}
                  </div>
                  <SelectVisaFreeMatch
                    options={passportOpts}
                    data={resultData.freeVisaCountries}
                    selectedOption={selectedTotalMatch}
                    handleVisaFreeSelect={(option) => handleVisaFreeSelect(option)}
                    handleVisaFreeSelectClear={() => handleVisaFreeSelectClear()}
                    placeholder="Can I travel visa-free to?"
                  />
                </Fragment>
              )}
            </div>

          </div>

        </div>
        <div className="col-12 col-md">
          <div className="row">
              {renderSelect('passport')}
              {renderSelect('program')}
          </div>
        </div>

      </div>

      {isPopUpOpen && (
        <PopUp
           isOpen={isPopUpOpen}
           onClose={handlePopUpClose}
        >

          <PopUpContentVisaList
            title={showPopUpCountryHeader ? '' : "Full Visa Free List"}
            country={`${popUpData.label} Visa Free List`}
            code={popUpData.value}
            list={popUpData.freeVisaCountries}
            fileUrl={popUpData.free_visa_url || ''}
            showHeaderVisaRequiredIcon={false}
            showHeaderCountryInfo={showPopUpCountryHeader}
          />

        </PopUp>
      )}

    </div>
  );
  }

  export default ImprovePassport;