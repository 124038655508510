import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styles from  './global-rank.module.scss';
import SelectCustom from '../common/react-select-custom/select-custom';
import PopUp from '../common/popup/popup';
import PopUpContentGraph from '../common/popup/popup-content-graph';
import PopUpContentVisaList from '../common/popup/popup-content-visa-list';
import MapCountries from '../map/map-countries';
import CountryFlag from '../common/country-flag/country-flag';
import Button from '../common/button/button';
import LoadingSpinner from '../common/loading/loading-spinner';
import useData from '../../hooks/useData';
import useVisaData from '../../hooks/useVisaData';
import { visaGroups } from '../../config/project-config';
import { getNumberWithOrdinalWithSuperscript, renderHTML } from '../../utils/utils';
import { getTotalVisaListOfCategory, getPassportInfo} from '../../utils/convert';
import SelectVisaFreeMatch from '../common/react-select-custom/select-visa-free-match';
import PageIntro from '../common/page-intro/page-intro';
import IconSectionGlobalRank from '../common/icon/icon-section-global-rank';
import { downloadRankingApiService } from '../../services/api';

const GlobalRank = ({countryCode}) => {

    const {countries, currentYear, yearsOptions, passportOptions, rankUrls} = useData();
    const {visaData, fetchVisaData, visaDataLoading} = useVisaData();

    const [selectedYear, setSelectedYear] = useState('');
    const [selectedPassport, setSelectedPassport] = useState('');
    const [hoveredRow, setHoveredRow] = useState('');
    const [popUpListOpen, setPopUpListOpen] = useState(false);
    const [popUpGraphOpen, setPopUpGraphOpen] = useState(false);
    const [popUpGraphData, setPopUpGraphData] = useState('');
    const [fileUrl, setFileUrl] = useState('');

  //   const updateFileUrl = (year) => {
  //     const filteredData = rankUrls.filter(item => item.year === year);
  //     if (filteredData.length === 0) {
  //         setFileUrl('');
  //         return;
  //     }
  //     const mostRecent = filteredData.reduce((prev, current) => (prev.month > current.month) ? prev : current);
  //     setFileUrl(mostRecent.file);
  // };

      const updateFileUrl = useCallback((year) => {
        const filteredData = rankUrls.filter(item => item.year === year);
        if (filteredData.length === 0) {
            setFileUrl('');
            return;
        }
        const mostRecent = filteredData.reduce((prev, current) => (prev.month > current.month) ? prev : current);
        setFileUrl(mostRecent.file);
    }, [rankUrls]);


    useEffect(() => {
      setSelectedYear({label: currentYear, value: currentYear});
      updateFileUrl(currentYear);
    },[currentYear, updateFileUrl]);

    useEffect(() => {
      const fetchAndSetSelectedData = async () => {
        if (passportOptions && countryCode) {
          const defaultOption = passportOptions.filter(passport => passport.value.toLowerCase() === countryCode);
          if (defaultOption[0]) {
            setSelectedPassport(defaultOption[0]);
          }
        }
      };

      fetchAndSetSelectedData();
    }, [countryCode, passportOptions]);

    const countriesList = useMemo(()=> {

      let result = [];
       // Function to sort the countries based on the selected year
      const sortBySelectedYear = (countryA, countryB) => {
        const rankA = countryA.data[selectedYear.value] ? countryA.data[selectedYear.value].rank : Number.POSITIVE_INFINITY;
        const rankB = countryB.data[selectedYear.value] ? countryB.data[selectedYear.value].rank : Number.POSITIVE_INFINITY;

        if (rankA !== rankB) {
          return rankA - rankB;
        }

        // If ranks for the selected year are the same, sort by country name
        return countryA.country.localeCompare(countryB.country);
      };

      if(selectedYear){
          // let sortedData = countries.sort((a, b) => a.data[selectedYear.value].rank - b.data[selectedYear.value].rank);
          let sortedData = countries.sort(sortBySelectedYear);
          if(selectedPassport){
            const selectedRow = sortedData.find((row) => row.code === selectedPassport.value);

            const selectedIndex = sortedData.indexOf(selectedRow);
              // if(selectedIndex === 0) return;
            const rowToMove = sortedData.splice(selectedIndex, 1)[0];
              sortedData.unshift(rowToMove);

          }

          result = sortedData;
      }

      return result;

    },[countries, selectedYear, selectedPassport]);

    const visaTotalMergedList = useMemo(() => {

      // Check if visaData is empty or undefined
      if (!visaData || Object.keys(visaData).length === 0) {
        // Return an empty array or some default value
        return [];
      }

      let visaFreeList = getTotalVisaListOfCategory(visaData,visaGroups.visa_free.categories, 'visa_free');
      let visaRequiredList = getTotalVisaListOfCategory(visaData,visaGroups.visa_required.categories, 'visa_required');
      let mergedList = [...visaFreeList, ...visaRequiredList];

      // Sort the merged array alphabetically based on 'name'.
      mergedList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

      // Removed the console.log for production code. Keep it if this is for debugging purposes.
      // console.log(mergedList);

      return mergedList;

    },[visaData]);

    const getAllPassportOfYear = (data, year) => {

      return data.map((passport) => {

        const type = getColorType(passport.data[year] ? passport.data[year].rank : 0);
        const note = passport.data[year] ? (`<div><span>${getNumberWithOrdinalWithSuperscript(passport.data[year].rank)}</span> Rank IN ${year}</div><div><span>${passport.data[year].visa_free_count}</span> Visa-free destinations</div>`): '';

        return {
          name: passport.country,
          code: passport.code,
          type: type,
          note: note
        }

      })
    }

    const getColorType = (rank) => {
      switch(rank >= 0 ){
        case rank >= 1 && rank <= 10:
          return 'rank_type_1';
        case rank >= 11 && rank <=20:
          return 'rank_type_2';
        case rank >= 21 && rank <=30:
          return 'rank_type_3';
        case rank >= 31 && rank <= 40:
          return 'rank_type_4';
        case rank >= 41 && rank <= 50:
          return 'rank_type_5';
        case rank >= 51 && rank <= 60:
          return 'rank_type_6';
        case rank >= 61 && rank <= 70:
          return 'rank_type_7';
        case rank >= 71 && rank <= 80:
          return 'rank_type_8';
        case rank >= 81 && rank <= 90:
          return 'rank_type_9';
        default:
          return 'rank_type_10'
      }
    }

    const handleYearSelect = (selectedOption) => {
      setSelectedYear(selectedOption);
      updateFileUrl(selectedOption.value);
    }

    const handlePassportSelect = (selectedOption) => {
      setSelectedPassport(selectedOption);
    }

    const handlePassportSelectClear = () => {
      setSelectedPassport('');
    }

    const handlePopUpListOpen = (code) => {
      setPopUpListOpen(true);
      // setSelectedCountryCode(code);
      fetchVisaData(code);
    }


    const handlePopUpListClose = () => {
      setPopUpListOpen(false);
    }

    const handlePopUpGraphOpen = (code) => {
      setPopUpGraphOpen(true);
      setPopUpGraphData(getPassportInfo(code, countries));
    }

    const handlePopUpGraphClose = (code) => {
      setPopUpGraphOpen(false);
    }

    const handleListItemHover = (code) => {
      setHoveredRow(code !== null ? code : null);
    }

    /**
     * Call API and decide whether to open a modal or a PDF file
     * @param {Event} e Click Event
     */
    const onDownloadGlobalRankingClick = async (e) => {
      e.preventDefault();
      const result = await downloadRankingApiService.get();

      if (result.data === 'modal') {
        if (typeof window.$ !== 'undefined' && window.$("#hpi-ranking-modal") && window.$("#hpi-ranking-modal").modal) {
          window.$("#hpi-ranking-modal").modal('show');
        }
      } else {
        window.open(fileUrl, '_blank');
      }
    }

    if(!countries || !selectedYear.value) return <LoadingSpinner />;

    return (
      <div>

        <div className="appContainer">

          <PageIntro
            icon={<IconSectionGlobalRank />}
            heading="Global Passport Ranking"
            text={<div>
              <p>With historical data spanning 19 years, the Henley Passport Index is the only one of its kind based on exclusive data from the International Air Transport Authority (IATA). The index includes 199 different passports and 227 different travel destinations. Updated monthly, the Henley Passport Index is considered the standard reference tool for global citizens and sovereign states when assessing where a passport ranks on the global mobility spectrum.</p>
            </div>}
          />

          <div className="row">
            <div className="col-12 col-md-5 col-lg-4 mb40 mb-md-0">
              <div className={styles.list_container}>
                <div className={styles.list_container_top}>
                    <h5 className="mb-0 text-hp-blue">SELECT A YEAR</h5>
                    <SelectCustom
                      options={yearsOptions}
                      value={selectedYear}
                      placeholder="Type here"
                      onChange={handleYearSelect}
                      isSearchable={false}
                    />

                    {fileUrl && (
                      <div className="mt20">
                        
                        <Button
                          id="hpi-ranking-download"
                          linkUrl={fileUrl}
                          buttonText={`DOWNLOAD GLOBAL RANKING FOR ${selectedYear.value}`}
                          iconStyle="download"
                          target="_blank"
                          onClick={onDownloadGlobalRankingClick}
                        />
                      </div>
                    )}
                </div>

                <div className={`row ${styles.list_header}`}>
                  <div className="col-6"><h5 className="mb-0">Passport</h5></div>
                  <div className="col-3"><h5 className="mb-0">RANK IN<br /> {selectedYear.value}</h5></div>
                  <div className="col-3"><h5 className="mb-0">Access</h5></div>
                </div>
                <div>
                  <SelectVisaFreeMatch
                    options={passportOptions}
                    selectedOption={selectedPassport}
                    placeholder="Search for a passport"
                    singleMatch={false}
                    handleVisaFreeSelect={handlePassportSelect}
                    handleVisaFreeSelectClear={handlePassportSelectClear}
                  />
                </div>
                <div className={`${styles.list_body}`}>

                  {countriesList && countriesList.map((item,index)=>(
                    <div
                      key={index}
                      className={`row ${styles.list_item} ${selectedPassport && selectedPassport.value === item.code ? styles.activeItem : ''}`}
                      onMouseOver={()=>handleListItemHover(item.code)}
                      onMouseOut={()=>handleListItemHover(null)}
                    >
                      <div className="col-6">
                        <CountryFlag country={item.country} code={item.code} />
                      </div>
                      <div className="col-3">
                        {(item.data[selectedYear.value] && item.data[selectedYear.value].rank !==0) ?
                        (<span
                            className={styles.button}
                            onClick={()=>handlePopUpGraphOpen(item.code)}
                        >
                          <span className={`d-inline-block ${styles.buttonText}`}>
                            {renderHTML(getNumberWithOrdinalWithSuperscript(item.data[selectedYear.value].rank))}

                          </span>
                        </span>)
                        :'-'}
                      </div>
                      <div className="col-3">
                          {(item.data[selectedYear.value] && item.data[selectedYear.value].visa_free_count !== 0) ?
                          (
                            <span
                              className={`${styles.button} ${selectedYear.value !== currentYear ? styles.button_no_click: ''}`}
                              onClick={()=>handlePopUpListOpen(item.code)}
                            >

                              <span className={`d-inline-block ${styles.buttonText}`}>
                                {item.data[selectedYear.value].visa_free_count}
                              </span>
                            </span>
                          )
                          :'-'
                          }

                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-8">
              <MapCountries
                listOfCountries={getAllPassportOfYear(countriesList, selectedYear.value)}
                externalItemHovered={hoveredRow}
                selectedCode={selectedPassport? selectedPassport.value: ''}
              />
            </div>
          </div>
        </div>

        {popUpListOpen && visaData && (
        <PopUp
           isOpen={popUpListOpen}
           onClose={handlePopUpListClose}
        >
          {visaDataLoading
            ? <LoadingSpinner />
            : <PopUpContentVisaList
                // title="Full Visa List"
                country={`${visaData.country} Full Visa List`}
                code={visaData.code}
                list={visaTotalMergedList}
              />
          }

        </PopUp>
       )}

        {popUpGraphOpen && (
        <PopUp
          onClose={handlePopUpGraphClose}
          isOpen={popUpGraphOpen}
          type="graph"
        >
          {!popUpGraphData
            ? <LoadingSpinner />
            : <PopUpContentGraph
                code={popUpGraphData.code}
                country={`${popUpGraphData.country}'s Historical Ranking on the Henley Passport Index`}
                data={popUpGraphData}
                isInitialLoad={popUpGraphOpen}
                contentType="popup"
              />
          }
        </PopUp>
        )}

      </div>
    );
  }

  export default GlobalRank;