import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './assets/scss/app.scss';
import qs from "qs";
import MyPassport from './components/my-passport/my-passport';
import GlobalRank from './components/global-rank/global-rank';
import ComparePassport from './components/compare-passport/compare-passport';
import ImprovePassport from './components/improve-passport/improve-passport';
import FullVisaListPerCountry from './components/full-visa-list/full-visa-list';
import HistoricalRankPerCountry from './components/historical-rank/historical-rank';


function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

function getUrlParams() {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    return {
        countryCode: params.countrycode || '' // Include the country code, defaulting to an empty string if not present
    };
}

window.addEventListener("DOMContentLoaded", () => {
    const params = getUrlParams();
    // console.log(params);
    if (params) {
      
        const mypassport = getEl("[data-my-passport]")
        if (getEl("[data-my-passport]")) {
            const root = createRoot(mypassport);
            root.render(<MyPassport {...params} />);
        }

        const global = getEl("[data-global-rank]")
        if (getEl("[data-global-rank]")) {
            const root = createRoot(global);
            root.render(<GlobalRank {...params} />);
        }

        const fullVisaList = getEl("[data-full-visa-list]")
        if (getEl("[data-full-visa-list]")) {
            const root = createRoot(fullVisaList);
            root.render(<FullVisaListPerCountry {...params} />);
        }

        const historicalRank = getEl("[data-country-historical-rank]")
        if (getEl("[data-country-historical-rank]")) {
            const root = createRoot(historicalRank);
            root.render(<HistoricalRankPerCountry {...params} />);
        }

        const compare = getEl("[data-compare-passport]")
        if (getEl("[data-compare-passport]")) {
            const root = createRoot(compare);
            root.render(<ComparePassport {...params} />);
        }

        const improve = getEl("[data-improve-passport]")
        if (getEl("[data-improve-passport]")) {
            const root = createRoot(improve);
            root.render(<ImprovePassport {...params} />);
        }
    }
});
