const IconDownload = () =>{
    return(
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon fill="currentColor" fillRule="nonzero" points="7.5 2 7.5 11 8.5 11 8.5 2"></polygon>
                <polygon fill="currentColor" fillRule="nonzero" points="10.6464466 7.64644661 11.3535534 8.35355339 8.35355339 11.3535534 8 11.7071068 7.64644661 11.3535534 4.64644661 8.35355339 5.35355339 7.64644661 8 10.293"></polygon>
                <polygon fill="currentColor" fillRule="nonzero" points="3.5 10 3.5 12.5 12.5 12.5 12.5 10 13.5 10 13.5 13.5 2.5 13.5 2.5 10"></polygon>
            </g>
        </svg>
    )
}

export default IconDownload;