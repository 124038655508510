const  IconETA = () => {

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-344, -212)" stroke="currentColor">
                    <g transform="translate(0, 196)">
                        <g transform="translate(344, 16)">
                            <rect strokeLinejoin="round" x="3" y="2" width="10" height="7"></rect>
                            <path d="M3,9 C1.66666667,11 1,12.3333333 1,13 C1,14 1,14 2,14 C3,14 13,14 14,14 C15,14 15,14 15,13 C15,12.3333333 14.3333333,11 13,9 L3,9 Z" id="Path-7"></path>
                            <line x1="4" y1="11" x2="12" y2="11"></line>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconETA;
