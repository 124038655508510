import React from 'react';
// import imagePlaceholder from '../assets/img/image-placeholder.svg';

export const getNumberWithOrdinalWithSuperscript = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return `<span>${n}<sup>${s[(v - 20) % 10] || s[v] || s[0]}</sup></span>`;
};

export const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
      v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const slugify = (str) => {
  return str
      .toString()                           // Cast to string (optional)
      .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase()                  // Convert the string to lowercase letters
      .trim()                                  // Remove whitespace from both sides of a string (optional)
      // eslint-disable-next-line
      .replace(/\s+/g, '-')            // Replace spaces with -
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

export const formatNumberWithDot = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const groupBy = prop => data => {
  return data.reduce((dict, item) => {
    const { [prop]: _, ...rest } = item;
    dict[item[prop]] = [...(dict[item[prop]] || []), rest];
    return dict;
  }, {});
};

export const imageLink = (name) =>{
  // const defaultImage = imagePlaceholder;
  const imageUrl = './images/' + slugify(name) + '.jpg';
  return imageUrl;
}

export const flag = (name) =>{
  // const imageUrl = './flags/' + slugify(name) + '.png';
  const imageUrl = 'https://cdn.henleyglobal.com/themes/hgo/public/assets/img/flags/' + slugify(name) + '.png';
  return imageUrl;
}

export const passportImage = (name) =>{
  // const imageUrl = './passports/' + slugify(name).toUpperCase() + '.jpg';
  const imageUrl = 'https://cdn.henleyglobal.com/themes/hgo/public/assets/img/passports/' + slugify(name).toUpperCase() + '.png';
  return imageUrl;
}

export const percentFormat = (val) =>{
  return Math.round(val * 100)/100;
}

export const percentFormatThreeDecimal = (val)=>{
  return Math.round(val * 1000)/1000;
}

export const sortDataByName = (dataArr, name) =>{
  return dataArr.sort((a, b) => {
         if (a[name] < b[name]) {
         return -1;
         }
         if (a[name] > b[name]) {
         return 1;
         }
         return 0;
   });
}

export const addRank = (data) =>{
  const sortedData = [...data].sort((a, b) => b.hpp - a.hpp);
  let rank = 1;
  let prevHpp = sortedData[0].hpp;

  const dataWithRank = sortedData.map((obj) => {
    if (obj.hpp !== prevHpp) {
      rank++;
      prevHpp = obj.hpp;
    }
    return { ...obj, rank };
  });

  return dataWithRank
}
 

export const getLonAndLat = (countryCode, features) =>{
  let dataset = [];
  for(let i = 0; i <= features.length; i++){

    if(features[i] && features[i].properties.ISO2){
      let name = features[i].properties.ISO2;
      let longitude = features[i].properties.LON;
      let latitude = features[i].properties.LAT;
      let country = features[i].properties.NAME

      dataset[name] = {
        country: country,
        longitude: longitude,
        latitude: latitude
      }
    }
  }

  // console.log(dataset);

  return dataset[countryCode];
} 

export const hexToRgb = (hex) => {
  // Remove the # character if present
  hex = hex.replace("#", "");

  // Parse the hexadecimal value to decimal
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Return the RGB values in an array
  return [r, g, b];
}

export const renderHTML = (htmlString) => {
  return React.createElement('span', { dangerouslySetInnerHTML: { __html: htmlString } });
};


export const emptyDeep = (mixedVar, emptyValues = [undefined, null, '']) =>{
  let i, len
  for (i = 0, len = emptyValues.length; i < len; i++) {
      if (mixedVar === emptyValues[i]) {
          return true
      }
  }
  if (typeof mixedVar === 'object') {
      for (const item of Object.values(mixedVar)) {
          if (!emptyDeep(item, emptyValues)) {
              return false
          }
      }
      return true
  }
  return false
}

export const convertToObjectAndRemoveEmpty = (obj) =>{
  let result = [];
  for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== "") {
          result.push(obj[key]);
      }
  }
  return result;
}