import IconVisaFree from '../components/common/icon/icon-visa-free';
import IconVOA from '../components/common/icon/icon-voa';
import IconETA from '../components/common/icon/icon-eta';
import IconEVisa from '../components/common/icon/icon-evisa';
import IconVisaRequired from '../components/common/icon/icon-visa-required';
// import IconVisaGlobal from '../components/common/icon/icon-visa-global';

export const visaGroups = {
    'visa_free':{
      'label': 'Visa-free desinations',
      'note': 'Global visa-free access (out of 227 destinations)<br />Australia passport holders can travel without a visa.',
      'icon': <IconVisaFree />,
      'color': '#405363',
      'categories':[
        'visa_free_access', 
        'visa_on_arrival', 
        'electronic_travel_authorisation'
      ]
    },
    'visa_required':{
      'label': 'Visa required destinations',
      'note': 'Global visa required access (out of 227 destinations)<br />Australia passport holders can travel without a visa',
      'icon': <IconVisaRequired />,
      'color': '#DFE1E2',
      'categories':[
        'visa_online',
        'visa_required'
      ]
    }
  }
  
  export const visaTypes = {  
    'visa_free_access':{
      'label': 'Visa-free access',
      'short_label': '',
      'note': 'passport holders can travel to this destination without obtaining a visa in advance.',
      'color':'#92D19B',
      'icon': null,
    },
    'visa_on_arrival':{
      'label': 'Visa on Arrival (VOA)',
      'short_label': 'VOA',
      'note': 'passport holders are eligible to obtain a VOA when entering this destination.',
      'color': '#BAE6B6',
      'icon': <IconVOA />
    },
    'electronic_travel_authorisation':{
      'label': 'Electronic travel authority (ETA)',
      'short_label': 'ETA',
      'note': 'passport holders are eligible to travel to this destination with an ETA.',
      'color':'#E5F6E4',
      'icon': <IconETA />
    },
    'visa_online':{
      'label': 'Electronic Visa (E-Visa)',
      'short_label': 'E-VISA',
      'note': 'passport holders are required to obtain a government-approved e-Visa before traveling to this destination.',
      'color': '#FCDFDF',
      'icon': <IconEVisa />
    },
    'visa_required':{
      'label': 'Visa Required',
      'short_label': '',
      'note': 'passport holders are required to obtain a visa before traveling to this destination.',
      'color': '#E48A94',
      'icon': null
    }
  }

  // export const noteTextPopUp = (visaTypeName, countryName) =>{
  //   switch (visaTypeName !== ''){
  //     case 'visa_free_group':
  //       return `Global visa-free access (out of 227 destinations)<br />${countryName} passport holders can travel without a visa.`;
  //     case 'visa_required_group':
  //       return ``
  //   }
  // }


  export const mapBgColours = {
    'rank_type_1': '#405363',
    'rank_type_2': '#536472',
    'rank_type_3': '#667582',
    'rank_type_4': '#798691',
    'rank_type_5': '#8C98A1',
    'rank_type_6': '#9FA9B1',
    'rank_type_7': '#B3BAC1',
    'rank_type_8': '#C5CBD0',
    'rank_type_9': '#D9DDE0',
    'rank_type_10': '#ECEDEF',
    'visa_free_access': '#405363',
    'visa_on_arrival': '#405363',
    'electronic_travel_authorisation':'#405363',
    'visa_online':'#DFE1E2',
    'visa_required':'#DFE1E2'
}
  
  // export const emptyVisaTypes = (visaGroupName) =>{
  //   const emptyObject = {};
  
  //   Object.keys(visaGroupName).forEach((key) => {
  //     emptyObject[key] = [];
  //   });
  
  //   return emptyObject;
  // }

  export const emptyVisaFreeTypes = Object.keys(visaGroups.visa_free.categories).reduce((acc, key) => {
    acc[visaGroups.visa_free.categories[key]] = [];
    return acc;
  }, {});

  export const emptyVisaRequiredTypes = Object.keys(visaGroups.visa_required.categories).reduce((acc, key) => {
    acc[visaGroups.visa_required.categories[key]] = [];
    return acc;
  }, {});