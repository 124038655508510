import React from 'react';
import styles from  './passport-card.module.scss'; 
import Button from '../button/button';
import CloseButton from '../button/button-close';
import ImageWithFallback from '../../../utils/image';
import { flag, passportImage, renderHTML, getNumberWithOrdinalWithSuperscript } from '../../../utils/utils';

const PassportCard = ({
    country, 
    code, 
    rank,
    cardType, 
    visaFreeCountries, 
    visaFileLink,
    visaFileButtonText,
    visaListButtonText, 
    handleVisaListClick,
    handleDelete,
    showInfo = true,
    showButtons = true,
    customCardClass,
}) => {

  const IconRBI = () =>{
    return(
      <div className={styles.program__icon}>
        <svg width="54.2246154px" height="37.5334962px" viewBox="0 0 54.2246154 37.5334962">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-900.7321, -658.8032)" stroke="#FFFFFF">
                    <g transform="translate(865, 505)">
                        <g transform="translate(18.7869, 110)">
                            <g transform="translate(0, 0)">
                                <g transform="translate(16.9452, 43.8032)">
                                    <rect x="0.5" y="0.5" width="53.2246154" height="36.5334962" rx="10"></rect>
                                    <line x1="32.4233035" y1="24.6715361" x2="39.2013804" y2="24.6715361"></line>
                                    <line x1="32.4233035" y1="18.7330982" x2="45.9794574" y2="18.7330982"></line>
                                    <line x1="32.4233035" y1="12.8022974" x2="45.9794574" y2="12.8022974"></line>
                                    <path d="M9.40458173,28.4678436 C9.9129375,23.3843001 12.4547163,20.8425284 17.0299183,20.8425284 C21.6051202,20.8425284 24.146899,23.3843001 24.6552548,28.4678436"></path>
                                    <ellipse id="Oval" cx="17.1146442" cy="13.3866646" rx="6.27807692" ry="6.27805801"></ellipse>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </div>
    )
  }

  const renderCardTypeImage = (cardType, code) => {
    if(cardType === 'RBI'){
      return <IconRBI />;
    }
    return <ImageWithFallback src={passportImage(code)} />;
  }

  return (
    <div className={`${styles.card} ${customCardClass}`}>
        <div className={styles.card__header}>
            <ImageWithFallback src={flag(code)} className={styles.flag}/>
            <div className={`${styles.countryname} card-country-name`}>{country}</div>
            <CloseButton onClick={handleDelete} />
        </div>
        <div className={`${styles.card__body} ${showInfo ? '' : styles.hide_els_md}`}>
          <div className="row m-0 align-items-center">
            <div className={`col-6 ${styles.card__image__wrapper}`}>
              <div className={styles.card__image__wrapper__inner}>
                {renderCardTypeImage(cardType, code)}
              </div>
            </div>
            <div className="col-6 p-0">
              {cardType === 'passport' && (
                <div className={styles.card__text}>
                {renderHTML(getNumberWithOrdinalWithSuperscript(rank))}
                <div className={styles.label__text}>Current Ranking</div>
              </div>
              )}
              <div className={styles.card__text}>
                {visaFreeCountries}
                <div className={styles.label__text}>visa-free destinations</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.card__buttons__wrapper} ${showButtons ? '' : styles.hide_els_md}`}>
            <Button 
              iconStyle="eye"
              buttonText={visaListButtonText}
              onClick={handleVisaListClick}
            />
            <Button 
              buttonText={visaFileButtonText}
              linkUrl={visaFileLink}
              target="_blank"
              iconStyle="download"
            />
        </div>
    </div>
  );
}

export default PassportCard;