import React from 'react';
import styles from  './page-intro.module.scss'; 
import IconIATA from '../icon/icon-IATA';

const PageIntro = ({
  icon,
  heading,
  text,
}) => {

  return (
    <div className={styles.page_intro}>
    
        <div className="row justify-content-between align-items-end">
            <div className="col-12 col-md-8"> 
              <div className={`d-flex flex-row ${styles.page_heading}`}>
                {/* <div className={`${styles.icon_container}`}><div className={styles.icon}>{icon}</div></div> */}
                <div className={`${styles.heading}`}><h2 className="mb-0">{heading}</h2></div>
              </div>

              <div className={`${styles.text}`}>
                  {text}
              </div>
            </div>
            <div className={`col-auto col-md-auto mt40 mt-md-0`}>
              <div className={styles.logo}>
                <IconIATA />
              </div>
            </div>
        </div>
    </div>
  );
}

export default PageIntro;