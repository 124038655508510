import React, {useState, useEffect} from 'react';
import styles from "./popup.module.scss";
import CountryFlag from '../country-flag/country-flag';
import { visaGroups } from '../../../config/project-config';
import VisaItem from '../visa-item/visa-item';
import Button from '../button/button';
import Search from '../input/search/search';

const PopUpContentVisaList = ({
    country,
    code,
    title,
    list,
    fileUrl,
    showHeaderCountryInfo = true,
    showHeaderVisaFreeIcon = true,
    showHeaderVisaRequiredIcon = true,
}) => {

    const downloadButtonText = showHeaderVisaRequiredIcon ? 'FULL VISA LIST': 'VISA FREE LIST';

    const [filteredList, setFilteredList] = useState(list);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setFilteredList(list);
    }, [list]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        filterList(value);
    };

    // Function to filter countries based on search term
    const filterList = (value) => {
        const filtered = list.filter(country =>
            country.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredList(filtered);
    };

    // Function to clear the search input and reset the filtered list
    const handleClearSearch = () => {
        setSearchTerm('');
        setFilteredList(list);
    };


    return(
        <div className={styles.cardListContainer}>
            <div className={styles.cardHeader}>
                <div className={styles.card_header__top}>
                    {showHeaderCountryInfo && (
                        <CountryFlag country={country} code={code} />
                    )}

                    {title && (<h3>{title}</h3>)}

                    {fileUrl && (
                        <div className={styles.button__wrapper}>
                            <Button
                                buttonText={downloadButtonText}
                                iconStyle="download"
                                linkUrl={fileUrl}
                                target="_blank"
                            />
                        </div>
                    )}

                </div>

                <div className={styles.card_header__labels}>
                    <div className="row">
                        {showHeaderVisaFreeIcon && (
                            <span className={`col-6 d-inline-flex align-items-center ${styles.cardHeaderIconWrapper}`}>
                                <span className={`${styles.cardHeaderIcon} mr-1`}  style={{backgroundColor: visaGroups["visa_free"].color, color:'#fff'}}>
                                {visaGroups["visa_free"].icon}
                                </span>
                                VISA-FREE
                            </span>
                        )}
                        {showHeaderVisaRequiredIcon && (
                            <span className={`col-6 d-inline-flex align-items-center ${styles.cardHeaderIconWrapper}`}>
                            <span className={`${styles.cardHeaderIcon} mr-1`}  style={{backgroundColor: visaGroups["visa_required"].color, color:'rgb(64, 83, 99)'}}>
                            {visaGroups["visa_required"].icon}
                            </span>
                            VISA REQUIRED
                            </span>
                        )}
                    </div>
                </div>

                <div className={styles.card_header__search}>
                    <Search
                        placeholder="Can I travel visa-free to?"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onClear={handleClearSearch}
                    />
                </div>

            </div>
            <div className={`${styles.cardBody} ${styles.cardBodyList}`}>
                <ul>
                    {filteredList && filteredList.map((item, index) => (
                    <li key={index}>
                        <VisaItem
                            country={item.name}
                            code={item.code}
                            visaGroupName={item.groupName}
                            visaType={item.type||''}
                        />
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopUpContentVisaList;