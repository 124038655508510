import React from 'react';
import { flag } from '../../../utils/utils';
import  styles from  './country-flag.module.scss'; // import CSS file with animation styles

const  CountryFlag= ({country, code, longText}) => {
    return (
      <span className={`${styles.countryFlag} ${longText ? styles.countryNameTextWrap : ''}`}>
          <img src={flag(code)} alt={country} />
          <span className={styles.countryName}>{country}</span>
      </span>
    )

}

export default CountryFlag;
