export const MAX_BOUNDS = [
    [-180, -15], // Southwest coordinates
    [185, 75], // Northeast coordinates
  ];

export const MIN_ZOOM = 1;
export const MAX_ZOOM = 8;
export const LONGITUDE_FLIP = 50;
  
export const DEFAULT_MAP_STATE = {
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    zoom: 0,
    longitude: 0,
    latitude: 0,
};



