const IconSectionPassport = () => {

    return (
        <svg width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd" stroke="#405363" transform="translate(6 3)">
                <rect width="27" height="33" x=".5" y=".5"/>
                    <line x1="25.5" x2="25.5" y1=".5" y2="33.5" strokeLinecap="square"/>
                    <line x1="5.5" x2="20.5" y1="24.5" y2="24.5" strokeLinecap="square"/>
                    <line x1="5.5" x2="20.5" y1="28.5" y2="28.5" strokeLinecap="square"/>
                <circle cx="13" cy="13" r="7.5"/>
                <line x1="5.286" x2="20.143" y1="13" y2="13"/>
                <line x1="5.286" x2="20.714" y1="13" y2="13" transform="rotate(90 13 13)"/>
                <path d="M3.29826815,11.0125539 C4.70074715,13.662482 7.2347741,14.9874461 10.900349,14.9874461 C14.5659239,14.9874461 17.079516,13.662482 18.4411253,11.0125539" transform="rotate(90 10.87 13)"/>
                <path d="M7.28571429,7.85714286 C8.344189,9 10.2566622,9.57142857 13.0231338,9.57142857 C15.7896054,9.57142857 17.6866561,9 18.7142857,7.85714286"/>
                <path d="M7.28571429,16.4285714 C8.344189,17.5714286 10.2566622,18.1428571 13.0231338,18.1428571 C15.7896054,18.1428571 17.6866561,17.5714286 18.7142857,16.4285714" transform="matrix(1 0 0 -1 0 34.571)"/>
                <path d="M7.29826815,11.0125539 C8.70074715,13.662482 11.2347741,14.9874461 14.900349,14.9874461 C18.5659239,14.9874461 21.079516,13.662482 22.4411253,11.0125539" transform="matrix(0 1 1 0 1.87 -1.87)"/>
            </g>
        </svg>
    );

}

export default IconSectionPassport;
