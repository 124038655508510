import React from 'react';
import styles from "./search.module.scss";
import IconSearch from '../../icon/icon-search';
import IconRemove from '../../icon/icon-remove';


const Search = ({placeholder, value, onChange, onClear}) =>{


    return( 
        <div className={styles.search_container}>
            <div className={styles.search_icon}>
                <IconSearch />
            </div>
            <input 
                type="text" 
                placeholder={placeholder} 
                value={value}
                onChange={onChange}
            />
            {value && (
                <div className={styles.clearButton} onClick={onClear}>
                    <IconRemove />
                </div>
            )}
           
        </div>

    )
}

export default Search;