import fallback from "../assets/img/image-placeholder.svg";

const ImageWithFallback = ({ src, alt, className, fallBackSrc = fallback}) => {
  return (

      <img
        src={src}
        alt={alt}
        className={className}
        onError={(e) => (e.currentTarget.src = fallBackSrc)}
      />
  );
}

export default ImageWithFallback;