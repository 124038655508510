import axios from "axios";
// import countryTopoJson from '../config/countries-simplified-20.json';
import {useState, useEffect} from "react";
import { feature as topojsonParse } from "topojson-client";

const useCountriesData = () => {
    const [loadedCountriesData, setLoadedCountriesData] = useState(null);
  
    useEffect(() => {
      // Only load shapes once.
      (async () => {
        const res = await axios(`./countries-simplified-20.topojson`);
        const fc = topojsonParse(res.data, "map (2)");
        // console.log(fc);
        setLoadedCountriesData(fc);
      })();
      
    }, []);
  
    return loadedCountriesData;
};



// const useContriesData = () =>{
//   const loadedCountriesData = topojsonParse(countryTopoJson[0], "map2");
//   // console.log(test);

//   return loadedCountriesData;
// }

export default useCountriesData;