const  IconVisaFree = () => {

    return (
        <svg width="13.5185132px" height="11.5207154px" viewBox="0 0 13.5185132 11.5207154">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-345.2407, -165.3492)" stroke="currentColor" strokeWidth="2">
                    <g transform="translate(0, 147)">
                        <g transform="translate(346, 19)">
                            <polyline points="0 4.66666667 4 9.33333333 12 0"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconVisaFree;
