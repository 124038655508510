import React, {useState} from "react";
import {components} from 'react-select';
import SelectCustom from "./select-custom";
import VisaItem from "../visa-item/visa-item";
import IconSearch from "../icon/icon-search";
import IconRemove from "../icon/icon-remove";
import styles from './react-select-custom.module.scss'

const SelectVisaFreeMatch = ({
    options, 
    selectedOption,
    data, 
    singleMatch = true,
    placeholder = 'Search visa free access',
    handleVisaFreeSelect = () => {}, 
    handleVisaFreeSelectClear = () => {}
}) => {

   const [visaFreeCheckMap, setVisaFreeCheckMap] = useState(null);

   const handleSelect = (option) =>{
 
    if(singleMatch){
      const visaMatchedOnMapSelection = data.find((item) => item.code === option.value);

      if(visaMatchedOnMapSelection){
          setVisaFreeCheckMap(true);
      }else{
          setVisaFreeCheckMap(false);
      }
    }

    handleVisaFreeSelect(option);

  }

  const handleClear = () =>{
    handleVisaFreeSelectClear();
  }

  const valueContainerCustom = (props) =>{
      return(
        <components.ValueContainer {...props}>
          <div className={styles.visafree__valueContainer}>
            <div className={styles.visafree__icon__search}><IconSearch /></div>
            <div className={styles.visafree__singleValue}>{props.children}</div>
          </div>
        </components.ValueContainer>
      )
  }

  return (
    <div className={styles.visafree__select__wrapper}>
      {/* <div className="d-flex justify-content-between align-items-start">
        <div className={styles.visaCheckClearWrapper}>
            {selectedOption && (
            <div className={styles.visaCheckClear} onClick={handleClear}>
                <IconClose />
            </div>
            )}
        </div>
      </div> */}
        <div className={styles.visafree__select}>
          <SelectCustom
            options={options}
            value={selectedOption}
            placeholder={placeholder}
            onChange={(option) => handleSelect(option)}
            isSearchable
            selectType="passport"
            showDropdownIndicator={false}
            inputControlBorderBottom="1px solid #DFE1E2"
            otherCustomComponents={{ValueContainer: valueContainerCustom}}
          />
           {selectedOption && (
              <div className={styles.visafree__clear} onClick={handleClear}>
                <IconRemove />
              </div>
            )}
        </div>
        {(singleMatch && selectedOption) && (
          <VisaItem 
            country={selectedOption.label}
            code={selectedOption.value}
            visaGroupName={visaFreeCheckMap ? 'visa_free': 'visa_required'}
          />
        )}

    </div>
  );
};

export default SelectVisaFreeMatch;
