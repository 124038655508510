const IconSectionImprove = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd" stroke="#405363" transform="translate(4.058 3.62)"><path d="M4.20992447,15.88 L9.52147682,15.88 L16.3528408,22.7424146 L9.24755104,29.88 L4.20981515,29.88 L9.23164854,24.8352034 L-1.55834961,24.8780123 L-1.55834961,20.8780046 L9.14321543,20.8352112 L4.20992447,15.88 Z" transform="rotate(-90 7.5 22.88)"/><path d="M31.5214768,10.88 L38.3528408,17.7424146 L31.247551,24.88 L26.2097841,24.88 L31.1868427,19.88 L10.4416504,19.88 L10.4416504,15.88 L31.1868427,15.88 L26.2097841,10.88 L31.5214768,10.88 Z" transform="rotate(-90 24.5 17.88)"/></g>
        </svg>
    );

}

export default IconSectionImprove;
