const IconSectionCompare = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd" stroke="#405363" transform="translate(4 3.62)"><path d="M24.5798264.88 31.4111904 7.74241457 24.3059006 14.88 21.2681879 14.88 28.2365147 7.88 21.2681879.88 24.5798264.88ZM10.9352496 25.88 15.9133323 30.88 12.8757371 30.88 7.89826314 25.88 10.9352496 25.88ZM15.9133323 16.88 10.9352496 21.88 7.62427369 21.88 12.6018095 16.88 15.9133323 16.88Z"/><path d="M21.5798264,0.88 L28.4111904,7.74241457 L21.3059006,14.88 L16.2681337,14.88 L21.2451923,9.88 L0.5,9.88 L0.5,5.88 L21.2451923,5.88 L16.2681337,0.88 L21.5798264,0.88 Z"/><path d="M21.5798264,16.88 L28.4111904,23.7424146 L21.3059006,30.88 L16.2681337,30.88 L21.2451923,25.88 L0.5,25.88 L0.5,21.88 L21.2451923,21.88 L16.2681337,16.88 L21.5798264,16.88 Z" transform="matrix(-1 0 0 1 29.117 0)"/><rect width="2" height="4" x="28.5" y="21.88"/></g>
        </svg>
    );

}

export default IconSectionCompare;
