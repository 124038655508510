import React, {useState, useEffect, useCallback, useRef} from "react";
import useCountriesData from "../../hooks/useContriesData";
import { MAX_BOUNDS, MIN_ZOOM, MAX_ZOOM, DEFAULT_MAP_STATE} from "../../config/config";
// import { slugify } from "../../utilities/utils";
import { WebMercatorViewport } from "react-map-gl";
import useComponentSize from "@rehooks/component-size";
import DeckGL, { GeoJsonLayer} from "deck.gl";
import MapControls from "../common/map/map-controls/map-controls";
import MapToolTip from "../common/map/map-tooltip/map-tooltip";
import styles from "./map-countries.module.scss";
// import ListItem from "../common/list/ListItem";
// import { getCountryNameByISO3 } from "../../utilities/converter";
import { getLonAndLat, hexToRgb, renderHTML} from "../../utils/utils";
import { mapBgColours } from "../../config/project-config";
import VisaItem from "../common/visa-item/visa-item";
import { visaTypes } from "../../config/project-config";
import CountryFlag from "../common/country-flag/country-flag";

const MapCountries = ({
  listOfCountries, 
  selectedCode,
  externalItemHovered,
  embedType,
  currentCountry
  // onHover
}) => {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  const [allCountriesData, setAllCountriesData] = useState(null);

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [unselectedCountries, setUnselectedCountries] = useState([]);
  const [minZoom, setMinZoom] = useState(MIN_ZOOM);
  const [viewport, setViewport] = useState(DEFAULT_MAP_STATE);
  let mapRef = useRef(null);
  // let listRef = useRef(null);
  let { width, height } = useComponentSize(mapRef);
 
  const countriesData = useCountriesData();
  const popUpRef = useRef(null);

  useEffect(() => {
    if (width && height) {
      const newViewport = new WebMercatorViewport({
        width: width,
        height: height,
        maxZoom: MAX_ZOOM,
        minZoom: MIN_ZOOM,
        zoom: 0,
        longitude: 0,
        latitude: 0,
      }).fitBounds(MAX_BOUNDS);

      setMinZoom(newViewport.zoom);
      setViewport(newViewport);
    }
  }, [width, height]);

  const handleViewStateChange = useCallback((newState) => {
    if (newState.oldViewState !== null) {
      // clamp the zoom
      newState.viewState.zoom =
        newState.viewState.zoom > MAX_ZOOM ? MAX_ZOOM : newState.viewState.zoom;
      newState.viewState.zoom =
        newState.viewState.zoom < MIN_ZOOM ? MIN_ZOOM : newState.viewState.zoom;
      setViewport((v) =>
        v
          ? new WebMercatorViewport({
              ...v,
              ...newState.viewState,
            })
          : null
      );
    }
  }, []);

  const resetZoom = useCallback(() => {
    const newViewport = new WebMercatorViewport({
      width: width,
      height: height,
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,
      zoom: 0,
      longitude: 0,
      latitude: 0,
    }).fitBounds(MAX_BOUNDS);

    setViewport(newViewport);
  }, [width, height]);

  const zoomIncrement = useCallback(
    (amt) => {
      const newZoom = Math.max(
        Math.min(Math.max(viewport.zoom + amt, 0.001), MAX_ZOOM),
        minZoom
      );
      handleViewStateChange({
        viewState: {
          zoom: newZoom,
          transitionDuration: 5000,
        },
      });
    },
    [viewport, handleViewStateChange, minZoom]
  );



  useEffect(() => {

    const showVisibleCountries = (includeCountries, features) =>{
      const filteredFeatures = features.filter((feature) =>
        includeCountries.includes(feature.properties.ISO2)
      );
      const visibleCountries = {
        type: "FeatureCollection",
        features: filteredFeatures,
      };
      setSelectedCountries(visibleCountries);
    }

    const hideNotVisibleCountries = (includeCountries, features) =>{
        const unfilteredFeatures = features.filter(
          (feature) => !includeCountries.includes(feature.properties.ISO2)
        );
        const notVisibleCountries = {
          type: "FeatureCollection",
          features: unfilteredFeatures,
        };
        setUnselectedCountries(notVisibleCountries);
    }



    if(countriesData){
       
        let convertedListOfCountries = Object.values(listOfCountries);
        // console.log(convertedListOfCountries);
        let features = countriesData.features; 
        // console.log(convertedListOfCountries);
      

        let filteredData = [];
        let countriesCode = [];

        for(let i = 0; i < convertedListOfCountries.length; i++){
         
          // let obj = {}
          if(getLonAndLat(convertedListOfCountries[i].code, features)){
            let obj = {}
            // let countryNameFromList = getCountryNameByISO3(listOfCountries[i]);
            // let countryNameFromMapData = getLonAndLat(listOfCountries[i], features).country;
            obj['countryCode'] = convertedListOfCountries[i].code;
            // obj['country'] = countryNameFromList ? countryNameFromList : countryNameFromMapData;
            obj['country'] = convertedListOfCountries[i].name;
            obj['longitude'] = getLonAndLat(convertedListOfCountries[i].code, features).longitude;
            obj['latitude'] = getLonAndLat(convertedListOfCountries[i].code, features).latitude;
            obj['groupName'] = convertedListOfCountries[i].groupName || '';
            obj['type'] = convertedListOfCountries[i].type;
            // filteredData.push(obj);
            obj['note'] = convertedListOfCountries[i].note;
            filteredData.push(obj);
            countriesCode.push(convertedListOfCountries[i].code);
          }else{
            //console.log(convertedListOfCountries[i].code + convertedListOfCountries[i].name + ' is not found');
          }

        }

        const sortedData = filteredData.sort((a, b) => 
            a.country.localeCompare(b.country)
        );

        // console.log(sortedData);
   
        setAllCountriesData(sortedData);
        showVisibleCountries(countriesCode, features);
        hideNotVisibleCountries(countriesCode, features);

        // console.log(filteredData);

    }
  },[countriesData, listOfCountries]);

  useEffect(() => {
    setHoveredCountry(externalItemHovered ? externalItemHovered : null);
  },[externalItemHovered])

  const getProjectionCoordinates = (coords) => {
    if (viewport) {
      return viewport.project([coords[0], coords[1]]);
    }
    return [];
  };

  const getFillColor = (d) =>{
    const targetData = allCountriesData.filter(item => item.countryCode === d.properties.ISO2);
    const targetHexColor = mapBgColours[targetData[0].type];
    return hexToRgb(targetHexColor);
  }


  const handleHover = (data, fromMap = false) => {

    
   
    if (fromMap) {
      // if(data.object){
      //   const element = document.getElementById(`list-item-${slugify(getCountryNameByISO3(data.object.properties.ISO3))}`);
      //   listRef.current.scrollTo(0, element.offsetTop - 30);
      //   console.log(data.object.properties);
      // }
      setHoveredCountry(data.object ? data.object.properties.ISO2 : null);
      // setHoveredCountry(data.object.properties.ISO2);
      // onHover(data, fromMap);
      
    }else{
      // setHoveredCountry(data !== null ? data.country : null);
    }
  };


  // const handleClick = (data) =>{
  //   // setSelectedCountry(data.country);
  //   // popUpRef.current.scrollIntoView({ behavior: "smooth" });
  //   // handleViewStateChange({
  //   //   viewState: {
  //   //     zoom: 2,
  //   //     longitude: data.longitude,
  //   //     latitude: data.latitude,
  //   //     transitionDuration: 5000,
  //   //   },
  //   // });
  // }



  return (
    <div className={`${styles.mapColumn} ${embedType === 'visa' ? styles.mapRatio: styles.mapHeight }`}>
            
        <div className={styles.mapWrapper} ref={mapRef}>
                  <DeckGL
                    viewState={viewport}
                    controller={{scrollZoom:false, doubleClickZoom: false}}
                    onViewStateChange={handleViewStateChange}
                  >
                    <GeoJsonLayer
                      id="map-unselected-countries"
                      data={unselectedCountries ? unselectedCountries : null}
                      getLineColor={[255, 255, 255]}
                      getFillColor={[233, 233, 233]}
                      lineWidthUnits={"pixels"}
                      stroked={true}
                    />
                    <GeoJsonLayer
                      id="map-selected-countries"
                      data={selectedCountries ? selectedCountries : null}
                      getLineColor={[255, 255, 255]}
                      // getFillColor={[64, 83, 99]}
                      getFillColor = { d => getFillColor(d)}
                      lineWidthUnits={"pixels"}
                      stroked={true}
                      pickable={true}
                      onHover={(info) => handleHover(info, true)}
                    />
                  </DeckGL>

                  {allCountriesData &&
                    allCountriesData.map((d, index) => {
                      return (
                        <React.Fragment key={d.countryCode + index + "-tooltip"}>
                          {(hoveredCountry === d.countryCode || selectedCode === d.countryCode) && (
                            <MapToolTip 
                              top={getProjectionCoordinates([d.longitude,d.latitude,])[1]-2}
                              left={getProjectionCoordinates([d.longitude,d.latitude,])[0]}
                            >
                              {embedType === 'visa' ? (
                                <div className={styles.tooltip_map_visa}>
                                  <VisaItem 
                                    code={d.countryCode} 
                                    country={d.country}
                                    visaType={d.type}
                                    visaGroupName={d.groupName}
                                  />
                                  <div className={styles.tooltip_content}>
                                    {(d.type !== 'visa_free_access' && d.type !== 'visa_required')&&(
                                      <div className={styles.visa_type}>
                                        <span className={styles.visa_type_icon}>{visaTypes[d.type].icon}</span>
                                        <span className={styles.visa_type_label}>{visaTypes[d.type].label}</span>
                                      </div>
                                    )}
                                    <div>
                                      
                                    </div>
                                    {currentCountry} {visaTypes[d.type].note}
                                  </div>
                                </div>
                              ):(
                                <div>
                                  <div className={styles.tooltip_country_name}>
                                    <CountryFlag code={d.countryCode} country={d.country} />
                                  </div>
                                  <div className={styles.tooltip_content}>
                                      <div className={styles.rank_content}>
                                      {renderHTML(d.note)}
                                      </div>
                                  </div>
                                </div>
                              )}
                             
                              
                            </MapToolTip>
                          )}
                        </React.Fragment>
                      );
                    })}
                    <MapControls
                      zoomIncrement={() => zoomIncrement(0.5)}
                      zoomDecrement={() => zoomIncrement(-0.5)}
                      resetZoom={() => resetZoom()}
                    /> 
        </div>
            
        <div ref={popUpRef} className={styles.positionToScroll}></div>
    </div>
  );
}

export default MapCountries;