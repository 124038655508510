import React, { useState, useMemo, useEffect } from 'react';
import styles from  './my-passport.module.scss'; 
import SelectCustom from '../common/react-select-custom/select-custom';
import { 
  flag, 
  passportImage, 
  getNumberWithOrdinalWithSuperscript, 
  renderHTML} 
from '../../utils/utils';
import MapCountries from '../map/map-countries';
import LoadingSpinner from '../common/loading/loading-spinner';
import useData from '../../hooks/useData';
import useVisaData from '../../hooks/useVisaData';
import { 
  getPassportInfo, 
  getTotalVisaListOfCategory, 
} from '../../utils/convert';
import CloseButton from '../common/button/button-close';
import { visaGroups } from '../../config/project-config';
import ImageWithFallback from '../../utils/image';
import PopUpContentVisaList from '../common/popup/popup-content-visa-list';
import PopUpContentGraph from '../common/popup/popup-content-graph';
import SocialShare from '../common/social-share/social-share';
import CountryFlag from '../common/country-flag/country-flag';
import MapLegends from '../common/map/map-legends/map-legends';
import PageIntro from '../common/page-intro/page-intro';
import IconSectionPassport from '../common/icon/icon-section-passport';

const MyPassport = ({countryCode}) => {

    const {countries, currentYear, passportOptions, loading} = useData();
    const {visaData, fetchVisaData} = useVisaData();

    const [shareUrl, setShareUrl] = useState(false);
    const [selectedPassportOption, setSelectedPassportOption] = useState('');
    // const [selectedDestinMap, setSelectedDestinMap] = useState('');
    const [isPopupGraphOpen, setIsPopupGraphOpen]= useState(false);
    

    useEffect(() => {
      const fetchAndSetDefaultCountry = async () => {
        if (passportOptions && countryCode) {
          const defaultOption = passportOptions.filter(passport => passport.value.toLowerCase() === countryCode.toLowerCase());
          const currentUrl = window.location.href;
          if (defaultOption[0]) {
            setSelectedPassportOption(defaultOption[0]);
            await fetchVisaData(defaultOption[0].value);
            setShareUrl(currentUrl);
            setIsPopupGraphOpen(true);
          }
        }
      };
    
      fetchAndSetDefaultCountry();
    }, [countryCode, passportOptions, fetchVisaData]);


    const passportData = useMemo(() => {
      let result = {};
      if(selectedPassportOption.value){
        result =  getPassportInfo(selectedPassportOption.value, countries)
      }
  
      return result;
   
    },[countries, selectedPassportOption]);

    const visaGroupedList = useMemo(() => {
      let visaFreeList = [];
      let visaRequiredList = [];

      if(visaData){
        visaFreeList = getTotalVisaListOfCategory(visaData,visaGroups.visa_free.categories, 'visa_free');
        visaRequiredList = getTotalVisaListOfCategory(visaData,visaGroups.visa_required.categories, 'visa_required');  
      }
     return {visaFreeList, visaRequiredList};
   
    },[visaData]);

    const visaTotalMergedList = useMemo(() => {

      let mergedList = [...visaGroupedList.visaFreeList, ...visaGroupedList.visaRequiredList];

      // Sort the merged array alphabetically based on 'name'.
      mergedList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    
      return mergedList;
   
    },[visaGroupedList]);

    const handlePassportSelect = (option) =>{
      const shareUrl = `${window.location.href}?countrycode=${option.value}`;
      setSelectedPassportOption(option);
      fetchVisaData(option.value);
      setShareUrl(shareUrl);
      // setSelectedDestinMap('');
      setIsPopupGraphOpen(true);
    }


    const handleClearPassport = () =>{
      setSelectedPassportOption('');
    }

    if(loading) return <LoadingSpinner />;


    return (
      <div className="appContainer">

          <PageIntro 
            heading="My Passport"
            icon={<IconSectionPassport />}
            text={<div><p>The Henley Passport Index compares the visa-free access of 199 different passports to 227 travel destinations. If no visa is required, then a score with value = 1 is created for that passport. The same applies if you can obtain a visa on arrival (VOA), a visitor’s permit, or an electronic travel authority (ETA) when entering the destination.</p><p>Where a visa is required, or where a passport holder has to obtain a government-approved electronic visa (e-Visa) before departure, a score with value = 0 is assigned. This also applies if you need pre-departure government approval for a visa on arrival.</p><p>The total score for each passport is equal to the number of destinations for which no visa is required (value = 1).</p></div>}
          />

          {!selectedPassportOption && (
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="column-select bg-hp-blue p-3">
                  <div className="select-wrapper-inner">
                    <h4>SELECT A PASSPORT</h4>
                    <SelectCustom
                        options={passportOptions}
                        value={selectedPassportOption}
                        placeholder="Type here"
                        onChange={handlePassportSelect}
                        darkTheme
                        selectType="passport"
                        isSearchable
                    />
                  </div>
                </div>
               
              </div>
            </div>
          )}
         
          {(selectedPassportOption && visaData) && (
            <div className={styles.main_wrapper}>
              <div className="row mb40">
                <div className="col-md-8">
                  <div className={`position-relative ${styles.passport_card}`}>
                    <CloseButton onClick={handleClearPassport} />
                    <div className="row">
                      <div className={`col-12 col-md-6 d-flex flex-column justify-content-between`}>
                  
                        <div className="mb40">
                            <div className={styles.passport_flag}>
                              <img src={flag(selectedPassportOption.value)} alt={selectedPassportOption.label}  />
                            </div>
     
                            <h2 className="mb-0">{passportData.country}</h2>
                        </div>
                  
                        {passportData.data[currentYear] && (
                          <div className="mt-0 mb-auto">
                            <div className={`mb40 ${styles.info}`}>
                              <h2 className="mb10">
                                {renderHTML(getNumberWithOrdinalWithSuperscript(passportData.data[currentYear].rank))}
                              </h2>
                              <h5 className="mb-0">Current Ranking</h5>
                            </div>
                            <div className={`${styles.info}`}>
                              <h2 className="mb10">
                                {passportData.data[currentYear].visa_free_count}
                              </h2>
                              <h5 className="mb-0">VISA-FREE DESTINATIONS</h5>
                            </div>
                          </div>
                        )}
                    
                        <div className={`mt40 mb-0 ${styles.buttonGroup}`}>
                            <SocialShare shareUrl={shareUrl} />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mt20 mt-md-0">
                        <ImageWithFallback 
                          src={passportImage(selectedPassportOption.value)}
                          alt={selectedPassportOption.label}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 position-relative mt40 mt-md-0">
                  <div className={`${styles.visa_list__wrapper}`}>
                      <PopUpContentVisaList 
                        showHeaderCountryInfo={false}
                        title="Full Visa List"
                        list={visaTotalMergedList}
                        fileUrl={passportData.visa_full_url}
                      />
                  </div>
                </div>
              </div>

              {visaData && Object.keys(visaData).length === 7 && (
                <div className={styles.map_wrapper}>
                    <div className={styles.map_country_label}>
                      <CountryFlag code={passportData.code} country={`${passportData.country}'s Global Access`} />
                    </div>
                    <div className="position-relative">
                      <MapLegends />
                      <MapCountries 
                        listOfCountries={visaTotalMergedList} 
                        embedType="visa"
                        currentCountry={passportData.country}
                      />
                    </div>
                </div>
              )}

              {passportData.data && (
                <div className="mt40">
                  <PopUpContentGraph 
                    code={passportData.code}
                    country={`${passportData.country}'s Historical Ranking on the Henley Passport Index`}
                    data={passportData}
                    isInitialLoad={isPopupGraphOpen}
                  />
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
  
  export default MyPassport;