const  IconArrowDown = () => {

    return (
        <svg width="13.4142136px" height="8.12132034px" viewBox="0 0 13.4142136 8.12132034">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rank-2" transform="translate(-218.2929, -561.2929)">
                    {/* <rect fill="#FFFFFF" x="0" y="0" width="1440" height="1814"></rect> */}
                    <g transform="translate(0, 502)" stroke="#C1C5C8" strokeWidth="2">
                        <g transform="translate(217, 55)">
                            <g transform="translate(2, 5)">
                                <polyline points="0 0 6 6 12 0"></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconArrowDown;
